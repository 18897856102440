import React, {
  useState,
  useCallback,
  FC,
  Dispatch,
  SetStateAction,
} from "react";
import {
  BoxWBorder,
  Container,
  Head,
  LoadingMarketChart,
  LoadingWallets,
  Logo,
  LogoContainer,
  LogoCoverIcon,
  LogoCoverIconCOntainer,
  ReloadIcon,
  Wrapper,
  useStyles,
} from "./SideBarStyles";
import { Text } from "../../../components/Text/Text";
import { text } from "../../../theme/text";
import { NavLink, useNavigate } from "react-router-dom";
import { useThemeContext } from "../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { cryptoSelector } from "../../../logic/crypto/CryptoSelectors";
import { Box } from "@material-ui/core";
import { COINGECKO_COINS } from "../../../data/coingecko";
import { CONFIG } from "../../../config";
import { MarketVolumeInfo } from "../../../components/MarketVolumeInfo";
import { useTranslation } from "react-i18next";
import { MarketItem } from "../../../components/MarketItem";
import { generalSelector } from "../../../logic/general/GeneralSelectors";
import { userSelector } from "../../../logic/user/UserSelectors";
import { useDependentValue } from "../../../helpers/customHooks";
import { Currency } from "../../../logic/general/GeneralRedux";
import { WalletItem } from "../../../components/WalletItem";
import {
  prettyStringToNumber,
  formatCardNumberWithSpaces,
} from "../../../helpers/formatHelpers";
import { cardsSelector } from "../../../logic/cards/CardsSelectors";
import { ZedpayCardFullInfo } from "../../../types/types";
import { CardsActions } from "../../../logic/cards/CardsRedux";
import {
  CryptoAccount,
  CryptoActions,
} from "../../../logic/crypto/CryptoRedux";
import { CloseButton } from "../../../components/HistoryBar/HistoryBarStyles";
import {
  SkeletonMarketChartItem,
  SkeletonMarketInfoItem,
  SkeletonWalletItem,
} from "./SkeletonLoaders";
import { appSettingsSelector } from "../../../logic/appSettings/AppSettingsSelectors";
import { useVerified } from "../../../hooks/useVerified";
import { showToast } from "../../../helpers/alertService";
import {
  getAdaptivePriceFormatter,
  getCurrencySymbol,
} from "../../../helpers/price";
import cn from "classnames";
import currency from "currency.js";

type Props = {
  mobile?: boolean;
  setVisible?: Dispatch<SetStateAction<boolean>>;
};

const SideBar: FC<Props> = ({ mobile, setVisible }) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const s = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId } = useSelector(userSelector);

  const {
    marketCap,
    marketVolume,
    showAvailableAccountsStillNotOpened,
    paymentsPageSelectedAccount,
    coingeckoMarketCharts: {
      data: marketCharts,
      fetching: fetchingMarketCharts,
    },
    getAccountsList: { data: myAccounts, fetching: fetchingMyAccounts },
    openAccount: { fetching: fetchingOpenAccount },
  } = useSelector(cryptoSelector);

  const {
    currencyListToOpen: { data: allAvailableAccountsToOpenData },
  } = useSelector(generalSelector);
  const { currency: userCurrency } = useSelector(appSettingsSelector);
  const {
    cardsOperationsPageSelectedCard,
    transactionsByCard,
    getMyCards: { data: cards },
  } = useSelector(cardsSelector);

  const userFullyVerified = useVerified();

  const [lastPressedOpenCurrencyId, setLastPressedOpenCurrencyId] =
    useState(-1);

  // Set(allAvailableAccountsToOpenData) - Set(myAccounts)
  const availableAccountsStillNotOpened: Currency[] = useDependentValue(() => {
    const openedAccountsIds = new Set();

    if (myAccounts)
      myAccounts.value.forEach((a) => openedAccountsIds.add(a.currency_id));

    if (allAvailableAccountsToOpenData)
      return allAvailableAccountsToOpenData.currencies.filter(
        (c) => !openedAccountsIds.has(c.id)
      );

    return [];
  }, [myAccounts, allAvailableAccountsToOpenData]);

  const handleSelectCard = useCallback(
    (card: ZedpayCardFullInfo) => {
      if (cards && transactionsByCard[card.id] === undefined)
        dispatch(
          CardsActions.cardTransactions.request({
            id: card.id,
          })
        );

      dispatch(CryptoActions.setPaymentsPageSelectedAccount(null));
      dispatch(CardsActions.setCardsOperationsPageShowDetails(false));
      dispatch(CardsActions.setCardsOperationsPageSelectedCard(card));
      navigate("/main/cardsOperations");
    },
    [cards, transactionsByCard]
  );

  const handleSelectAccount = useCallback((account: CryptoAccount) => {
    dispatch(CardsActions.setCardsOperationsPageShowDetails(false));
    dispatch(CardsActions.setCardsOperationsPageSelectedCard(null));
    dispatch(CryptoActions.setPaymentsPageSelectedAccount(account));
    dispatch(
      CryptoActions.setExchangeFromCoin({
        ...account,
        title: account.name,
        value: account.id,
      })
    );
    navigate("/main/payments");
  }, []);

  const handleOpenAccount = useCallback(
    (currencyId: number) => {
      dispatch(
        CryptoActions.openAccount.request({
          customer_id: userId as number,
          currency_id: currencyId,
        })
      );
      setLastPressedOpenCurrencyId(currencyId);
    },
    [userId]
  );

  const onClickOrderNewCard = useCallback(() => {
    if (userFullyVerified) {
      navigate("/main/order-new-card");
      setVisible && setVisible(false);
    } else {
      showToast({
        title: t("cards.verificationNotComplited"),
        info: t("cards.completeVerification"),
        type: "warn",
      });
    }
  }, [setVisible, userFullyVerified, t]);

  const userTotalBalance = useDependentValue<number>(() => {
    if (!myAccounts) return 0;

    const accountsTotal = myAccounts.total.find(
      (currency) => currency.Key === userCurrency.toUpperCase()
    );

    return prettyStringToNumber(accountsTotal?.Value ?? "0");
  }, [myAccounts, userCurrency]);

  const cardsTotalBalance = useDependentValue<number>(() => {
    if (!cards) return 0;

    let balance = 0;
    cards.forEach((c) => (balance += c.balance));

    return balance;
  }, [cards]);
  const onRefresh = useCallback(() => {
    dispatch(CryptoActions.coingeckoMarketCharts.request());
  }, []);
  return (
    <Wrapper mobile={mobile}>
      <Head>
        <NavLink to="/main">
          <LogoContainer>
            <Logo src="/images/newLogo.jpg" alt="logo" />
            <LogoCoverIconCOntainer>
              <LogoCoverIcon src="/images/mastercardLogo.png" />
              <LogoCoverIcon src="/images/visaLogo.png" />
            </LogoCoverIconCOntainer>
          </LogoContainer>
        </NavLink>

        {mobile && (
          <CloseButton
            style={{ marginLeft: "auto" }}
            onClick={() => setVisible && setVisible(false)}
          >
            X
          </CloseButton>
        )}
      </Head>
      <div id="MyCards"></div>
      <Text size="18px" color={text.colors.lightGray} margin="20px 0 12px 0">
        {t("sideBar.myCards")}
      </Text>
      {cards?.length ? (
        <Text size="21px" color={text.colors.green} margin="5px 0 12px 0">
          {t("sideBar.total")}: ${cardsTotalBalance}
        </Text>
      ) : null}

      {cards?.map((card) => (
        <Box
          key={`my_card_${card.id}`}
          className={cn(s.fullWidthItemContainer, {
            [s.fullWidthItemContainerSelected]:
              card.id === cardsOperationsPageSelectedCard?.id,
          })}
        >
          <WalletItem
            image={
              card.network === "Visa"
                ? require("../../../assets/images/visa.png")
                : require("../../../assets/images/mastercard.png")
            }
            imageObjectFit={"contain"}
            userHasThisWallet={false}
            // userCurrencySymbol={getCurrencySymbol(userCurrency)}
            userCurrencySymbol="$"
            balance={card.balance}
            cryptoBalance={card.balance}
            name={card.card_type}
            // @ts-ignore
            walletName={
              card.status === "Active"
                ? formatCardNumberWithSpaces(card.card_number)
                : ""
            }
            rightLabel={card.status === "Active" ? undefined : card.status}
            rightLabelMarginRight={theme.metrics.x2}
            alt={
              card.status === "Active"
                ? undefined
                : `${t("mainPage.currentCardStatusIs")}: ${card.status}`
            }
            marginVertical={theme.metrics.x3}
            onPress={() => handleSelectCard(card)}
          />
        </Box>
      ))}
      <Box className={cn(s.marginTop)}>
        <Container>
          <BoxWBorder onClick={onClickOrderNewCard}>
            <img
              src="/images/add-circle.svg"
              alt="asd"
              style={{ width: "24px", height: "24px" }}
            />
          </BoxWBorder>
          <Text
            color={theme.colors.white}
            size="18px"
            fontStyle={theme.fonts.f600}
            margin="0 0 0 10px"
          >
            {t("sideBar.orderNewCard")}
          </Text>
        </Container>
      </Box>
      <Box>
        <div id="MyWallets"></div>
        <Text size="18px" color={text.colors.lightGray} margin="60px 0 12px 0">
          {t("sideBar.myWallets")}
        </Text>
        <Text size="21px" color={text.colors.green} margin="5px 0 12px 0">
          {t("sideBar.total")}:{" "}
          {currency(
            userTotalBalance,
            getAdaptivePriceFormatter(
              userTotalBalance,
              getCurrencySymbol(userCurrency)
            )
          ).format()}
        </Text>
        {myAccounts ? (
          myAccounts?.value.map((account) => (
            <Box
              key={`my_account_${account.id}`}
              className={cn(s.fullWidthItemContainer, {
                [s.fullWidthItemContainerSelected]:
                  account.id === paymentsPageSelectedAccount?.id,
              })}
            >
              <WalletItem
                image={`${CONFIG.ZED_BASE_URL}/${account.currency_flag}`}
                userHasThisWallet={true}
                // userCurrencySymbol={getCurrencySymbol(userCurrency)}
                userCurrencySymbol="$"
                balance={
                  account.balance * prettyStringToNumber(account.currency_price)
                }
                cryptoBalance={account.balance}
                name={account.currency_code}
                walletName={account.name}
                marginVertical={theme.metrics.x3}
                onPress={() => handleSelectAccount(account)}
              />
            </Box>
          ))
        ) : (
          <LoadingWallets>
            <SkeletonWalletItem />
          </LoadingWallets>
        )}

        {!showAvailableAccountsStillNotOpened && (
          <Box className={cn(s.marginTop)}>
            <Container>
              <BoxWBorder
                onClick={() =>
                  dispatch(
                    CryptoActions.setShowAvailableAccountsStillNotOpened(true)
                  )
                }
              >
                <img
                  src="/images/add-circle.svg"
                  alt="asd"
                  style={{ width: "24px", height: "24px" }}
                />
              </BoxWBorder>
              <Text
                color={theme.colors.white}
                size="18px"
                fontStyle={theme.fonts.f600}
                margin="0 0 0 10px"
              >
                {t("sideBar.openNewWallet")}
              </Text>
            </Container>
          </Box>
        )}

        {showAvailableAccountsStillNotOpened &&
          availableAccountsStillNotOpened.map((currency) => (
            <Box
              key={`available_to_open_${currency.id}`}
              className={cn(s.fullWidthItemContainer)}
            >
              <WalletItem
                image={`${CONFIG.ZED_BASE_URL}/${currency.flag}`}
                // userCurrencySymbol={getCurrencySymbol(userCurrency)}
                userCurrencySymbol="$"
                balance={0}
                cryptoBalance={0}
                name={currency.name}
                marginVertical={theme.metrics.x3}
                actionButtonTitle={t("buttons.open") as string}
                onPressAction={() => handleOpenAccount(currency.id)}
                actionButtonDisabled={fetchingOpenAccount}
                fetchingAction={
                  fetchingOpenAccount &&
                  currency.id === lastPressedOpenCurrencyId
                }
              />
            </Box>
          ))}
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div id="MarketInfo"></div>
        <Text size="18px" color={text.colors.lightGray} margin="60px 0 12px 0">
          {t("sideBar.marketInfo")}
        </Text>
        <ReloadIcon
          fetching={fetchingMarketCharts}
          src="/images/reload.svg"
          onClick={() => onRefresh()}
          alt="reload img"
        />
      </div>
      <Box>
        <Box className={cn(s.marketCap)}>
          {marketCap && !fetchingMarketCharts ? (
            <MarketVolumeInfo
              title={t("marketCap")}
              value={marketCap.value}
              change={marketCap.change}
            />
          ) : (
            <SkeletonMarketInfoItem />
          )}
          {marketVolume && !fetchingMarketCharts ? (
            <MarketVolumeInfo
              title={t("volume30d")}
              value={marketVolume.value}
              change={marketVolume.change}
            />
          ) : (
            <SkeletonMarketInfoItem />
          )}
        </Box>

        {marketCharts && !fetchingMarketCharts ? (
          Object.keys(marketCharts).map((key: string) => {
            const currentPrice =
              marketCharts[key].prices[marketCharts[key].prices.length - 1][1];
            return (
              <MarketItem
                key={key}
                symbol={COINGECKO_COINS[key].symbol}
                name={key}
                currentPrice={currentPrice}
                change={marketCharts[key].priceChange ?? 0}
                loadingPricesData={fetchingMarketCharts}
                pricesData={marketCharts[key].prices}
                dataType={"array"}
                image={`${CONFIG.ZED_BASE_URL}${COINGECKO_COINS[key].image}`}
              />
            );
          })
        ) : (
          <LoadingMarketChart>
            <SkeletonMarketChartItem />
            <SkeletonMarketChartItem />
            <SkeletonMarketChartItem />
            <SkeletonMarketChartItem />
            <SkeletonMarketChartItem />
            <SkeletonMarketChartItem />
          </LoadingMarketChart>
        )}
      </Box>
    </Wrapper>
  );
};

export default SideBar;
