import { CONFIG } from "../config";

export type DefaultLocalStorageType = string | null;

const _createStoreFunction = <DataT = any>(localStorageKey: string) => (data: DataT) => {
  const s = JSON.stringify(data);
  localStorage.setItem(localStorageKey, s);
}

const _createGetFunction = (localStorageKey: string) => () => {
  const value = localStorage.getItem(localStorageKey);
  return value;
}

const _createDeleteFunction = (localStorageKey: string) => () => {
  localStorage.removeItem(localStorageKey);
}

export const saveUserTokenToLocalStorage = (token: string) => {
  localStorage.setItem("@user_token", token);
};

export const getUserTokenFromLocalStorage = _createGetFunction("@user_token");

export const deleteUserTokenFromLocalStorage = _createDeleteFunction("@user_token");

export const storeUserIdToLocalStorage = (userId: string) => {
  localStorage.setItem('@user_id', userId);
};

export const getUserIdFromLocalStorage = _createGetFunction("@user_id");

export const deleteUserIdFromLocalStorage = _createDeleteFunction("@user_id");

export const storeLanguageToLocalStorage = (language: string) => {
  localStorage.setItem('@user_language', language);
}

export const getLanguageFromLocalStorage = _createGetFunction('@user_language');

export const storeCurrencyToLocalStorage = (currency: string) => {
  localStorage.setItem('@user_currency', currency);
}

export const getCurrencyFromLocalStorage = _createGetFunction('@user_currency');

export const storeUserPasscodeToLocalStorage = (passcode: string) => {
  localStorage.setItem('@user_app_passcode', passcode);
}

export const getUserPasscodeFromLocalStorage = _createGetFunction('@user_app_passcode');
export const deleteUserPasscodeFromLocalStorage = _createDeleteFunction('@user_app_passcode');

export const storeListCountriesToLocalStorage = _createStoreFunction('@app_list_countries');
export const getListCountriesFromLocalStorage = _createGetFunction('@app_list_countries');

export const storeCurrencyListToLocalStorage = _createStoreFunction(`@app_${CONFIG.RUN_MODE.toLowerCase()}_list_all_currency`);
export const getCurrencyListFromLocalStorage = _createGetFunction(`@app_${CONFIG.RUN_MODE.toLowerCase()}_list_all_currency`);
export const deleteCurrencyListFromLocalStorage = _createDeleteFunction(`@app_${CONFIG.RUN_MODE.toLowerCase()}_list_all_currency`);

export const storeCurrencyListToOpenToLocalStorage = _createStoreFunction(`@app_${CONFIG.RUN_MODE.toLowerCase()}_list_currency_to_open`);
export const getCurrencyListToOpenFromLocalStorage = _createGetFunction(`@app_${CONFIG.RUN_MODE.toLowerCase()}_list_currency_to_open`);
export const deleteCurrencyListToOpenFromLocalStorage = _createDeleteFunction(`@app_${CONFIG.RUN_MODE.toLowerCase()}_list_currency_to_open`);

export const storeFaqToLocalStorage = _createStoreFunction('@app_faq');
export const getFaqFromLocalStorage = _createGetFunction('@app_faq');

export const storeAboutToLocalStorage = _createStoreFunction('@app_about_info');
export const getAboutFromLocalStorage = _createGetFunction('@app_about_info');

export const storeBankAccountsToLocalStorage = _createStoreFunction('@app_zedpay_bank_accounts');
export const getBankAccountsFromLocalStorage = _createGetFunction('@app_zedpay_bank_accounts');

export const storeTotalBalanceSnapshotToLocalStorage = _createStoreFunction('@app_total_balance_snapshot');
export const getTotalBalanceSnapshotFromLocalStorage = _createGetFunction('@app_total_balance_snapshot');
export const deleteTotalBalanceSnapshotFromLocalStorage = _createDeleteFunction('@app_total_balance_snapshot');

export const storeCardsAllowedCountriesToLocalStorage = _createStoreFunction('@app_cards_allowed_countries');
export const getCardsAllowedCountriesFromLocalStorage = _createGetFunction('@app_cards_allowed_countries');

export const storeCardFeaturesToLocalStorage = _createStoreFunction('@app_zedpay_card_features');
export const getCardFeaturesFromLocalStorage = _createGetFunction('@app_zedpay_card_features');

export const storeFaceIdEnabledToLocalStorage = _createStoreFunction('@app_zedpay_faceId_enabled');
export const getFaceIdEnabledFromLocalStorage = _createGetFunction('@app_zedpay_faceId_enabled');
export const deleteFaceIdEnabledFromLocalStorage = _createDeleteFunction('@app_zedpay_faceId_enabled');

export const storeSwiftCountriesToLocalStorage = _createStoreFunction('@app_swift_countries');
export const getSwiftCountriesFromLocalStorage = _createGetFunction('@app_swift_countries');

export const storeCardCommissionsToLocalStorage = _createStoreFunction('@app_zedpay_card_commissions');
export const getCardCommissionsFromLocalStorage = _createGetFunction('@app_zedpay_card_commissions');
export const deleteCardCommissionsFromLocalStorage = _createDeleteFunction('@app_zedpay_card_commissions');

export const storeCommissionsToLocalStorage = _createStoreFunction('@app_zedpay_service_commissions');
export const getCommissionsFromLocalStorage = _createGetFunction('@app_zedpay_service_commissions');
export const deleteCommissionsFromLocalStorage = _createDeleteFunction('@app_zedpay_service_commissions');

export const storeNftPlansToLocalStorage = _createStoreFunction('@app_zedpay_nft_plans');
export const getNftPlansFromLocalStorage = _createGetFunction('@app_zedpay_nft_plans');
export const deleteNftPlansFromLocalStorage = _createDeleteFunction('@app_zedpay_nft_plans');

export const storeNftCategoriesToLocalStorage = _createStoreFunction('@app_zedpay_nft_categories');
export const getNftCategoriesFromLocalStorage = _createGetFunction('@app_zedpay_nft_categories');
export const deleteNftCategoriesFromLocalStorage = _createDeleteFunction('@app_zedpay_nft_categories');

export const storeNftContractsToLocalStorage = _createStoreFunction('@app_zedpay_nft_smart_contracts');
export const getNftContractsFromLocalStorage = _createGetFunction('@app_zedpay_nft_smart_contracts');
export const deleteNftContractsFromLocalStorage = _createDeleteFunction('@app_zedpay_nft_smart_contracts');

export const storeUserCardsLabelsToLocalStorage = (data: any, zedUserId: string) => {
  localStorage.setItem(`@user_${zedUserId}_cards_labels`, JSON.stringify(data));
}

export const getUserCardsLabelsFromLocalStorage = (zedUserId: string) => {
  const value = localStorage.getItem(`@user_${zedUserId}_cards_labels`);
  return value;
}