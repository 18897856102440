import React, { useState, useCallback, useMemo } from "react";
import {
  ContentWrapper,
  Line,
  RightWrapper,
  Tab,
  TabsBar,
  Wrapper,
} from "./../PaymentsPage/PaymentsStyle";
import { Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { cardsSelector } from "../../../logic/cards/CardsSelectors";
import { useTranslation } from "react-i18next";
import { BoxData, TabData } from "../../../types/types";
import {
  CardsOperationsTabs,
  DEFAULT_ACTIVE_BOX,
  LoadBoxes,
  SendBoxes,
  UnloadBoxes,
} from "./cardsOperationsPageConstants";
import { useStyles } from "./CardsOperationsStyles";
import { BankCard } from "./../../../components/BankCard";
import {
  prettyCardExpiryMonth,
  prettyCardExpiryYear,
} from "../../../helpers/formatHelpers";
import { pastelColors } from "../../../theme/default/colors";
import { StringValueItem } from "../../../components/StringValueItem";
import { useThemeContext } from "../../../theme";
import { TotalBalance } from "../../../components/TotalBalance";
import { CustomButton } from "../../../components/CustomButton";
import { CardsActions } from "../../../logic/cards/CardsRedux";
import { LoaderCentered } from "../../../components/LoaderCentered";
import { NoData } from "../../../components/NoData";
import { ActivateCardModal } from "../../../components/ActivateCardModal";
import { useDependentValue } from "../../../helpers/customHooks";
import SelectMethod from "../SelectMethod";
import MethodOptional from "../MethodOptional";
import cn from "classnames";
import HistoryItem from "../../../components/HistoryBar/HistoryItem";
import { useNavigate } from "react-router-dom";

export type CardsOperationsProps = {};

const CardsOperations: React.FC<CardsOperationsProps> = ({}) => {
  // @ts-ignore
  const { t } = useTranslation();
  const s = useStyles();
  const { theme } = useThemeContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    getCVV: { fetching: fetchingCVV },
    cardTransactions: { fetching: fetchingTransactions },
    cardsOperationsPageSelectedCard,
    cardsOperationsPageShowDetails,
    cardsLabels,
    transactionsByCard,
  } = useSelector(cardsSelector);

  const tabsData: TabData[] = useMemo(
    () => [
      { id: CardsOperationsTabs.Load, title: t("cardsOperations.load") },
      { id: CardsOperationsTabs.Send, title: t("cardsOperations.send") },
      { id: CardsOperationsTabs.Unload, title: t("cardsOperations.withdraw") },
    ],
    [t]
  );

  const boxesData: BoxData[][] = useMemo(() => {
    const loadBoxes: BoxData[] = [
      {
        id: LoadBoxes.LoadUSDZ,
        iconSrc: "/images/zedxion.png",
        title: t("cardsOperations.loadUSDZ"),
        description: t("cardsOperations.loadUSDZDescription"),
        buttons: [
          { label: t("payments.safe"), iconSrc: "" },
          { label: t("payments.fast"), iconSrc: "" },
        ],
        autoSelect: true,
      },
    ];

    const sendBoxes: BoxData[] = [
      {
        id: SendBoxes.CardToCard,
        iconSrc: "/images/cards.png",
        title: t("cardsOperations.cardToCard"),
        description: t("cardsOperations.cardToCardDescription"),
        buttons: [{ label: t("payments.safe"), iconSrc: "" }],
        autoSelect: true,
      },
    ];

    const unloadBoxes: BoxData[] = [
      {
        id: UnloadBoxes.UnloadUSDZ,
        iconSrc: "/images/zedxion.png",
        title: t("cardsOperations.unloadUSDZ"),
        description: t("cardsOperations.unloadUSDZDescription"),
        buttons: [
          { label: t("payments.safe"), iconSrc: "" },
          { label: t("payments.fast"), iconSrc: "" },
        ],
        autoSelect: true,
      },
    ];

    return [loadBoxes, sendBoxes, unloadBoxes];
  }, [t]);

  const [activateCardModalVisible, setActivateCardModalVisible] =
    useState(false);
  const [activeTab, setActiveTab] = useState(CardsOperationsTabs.Load);
  const [activeBox, setActiveBox] = useState(DEFAULT_ACTIVE_BOX);

  const cardStatusDescriptionMessage = useDependentValue(() => {
    switch (cardsOperationsPageSelectedCard?.status) {
      case "Accepted":
        return t("bankCard.statusAcceptedMessage");
      case "Sent":
      case "Pending":
        return t("bankCard.statusSendOrPendingMessage");
      case "Rejected":
        return t("bankCard.statusRejectedMessage");
      case "Ready":
        return t("bankCard.statusReadyMessage");

      default:
        return "";
    }
  }, [cardsOperationsPageSelectedCard, t]);

  const handleSwitchCardVisible = () => {
    if (
      !cardsOperationsPageShowDetails &&
      cardsOperationsPageSelectedCard?.card_cvv === "***"
    )
      dispatch(
        CardsActions.getCVV.request({
          id: cardsOperationsPageSelectedCard.id,
        })
      );
    dispatch(
      CardsActions.setCardsOperationsPageShowDetails(
        !cardsOperationsPageShowDetails
      )
    );
  };

  const onClickTab = useCallback((tab: TabData) => {
    setActiveTab(tab.id);
    setActiveBox(DEFAULT_ACTIVE_BOX);
  }, []);

  const handleActivateCard = useCallback(() => {
    dispatch(CardsActions.setCardToActivate(cardsOperationsPageSelectedCard));
    setActivateCardModalVisible(true);
  }, [cardsOperationsPageSelectedCard]);

  // переименуешь, пока так оставлю :)
  const redirect = () => {
    const state = {
      step: 2,
    };
    navigate("/main/order-new-card", { state });
  };

  return (
    <Wrapper>
      {cardsOperationsPageSelectedCard && (
        <Box className={cn(s.rowFullWidth)}>
          <BankCard
            label={cardsLabels[cardsOperationsPageSelectedCard.id]}
            type={cardsOperationsPageSelectedCard.card_type}
            network={cardsOperationsPageSelectedCard.network}
            cvv={cardsOperationsPageSelectedCard.card_cvv}
            fetchingCVV={
              fetchingCVV && cardsOperationsPageSelectedCard.card_cvv === "***"
            }
            expiration={`${prettyCardExpiryMonth(
              cardsOperationsPageSelectedCard.card_exp_mth
            )}/${prettyCardExpiryYear(
              cardsOperationsPageSelectedCard.card_exp_year
            )}`}
            cardHolder={`${cardsOperationsPageSelectedCard.first_name} ${cardsOperationsPageSelectedCard.last_name}`}
            cardNumber={
              cardsOperationsPageSelectedCard.card_number.length === 16
                ? cardsOperationsPageSelectedCard.card_number
                : undefined
            }
            showDetails={cardsOperationsPageShowDetails}
            backgroundColor={pastelColors[0]}
            marginRight={theme.metrics.x4}
          />

          <Box className={cn(s.cardInfoCol)}>
            <Box>
              <TotalBalance
                totalBalance={cardsOperationsPageSelectedCard.balance}
                balanceLabelText={t("bankCard.CardBalance") as string}
                flexDirection={"row"}
                balanceTextFontSize={20}
              />
              <StringValueItem
                label={t("cardStatus")}
                value={cardsOperationsPageSelectedCard.status}
              />
            </Box>
            <CustomButton
              onPress={handleSwitchCardVisible}
              title={
                cardsOperationsPageShowDetails
                  ? (t("hideDetails") as string)
                  : (t("showDetails") as string)
              }
              bgColorActive={
                cardsOperationsPageShowDetails
                  ? theme.colors.white
                  : theme.colors.yellowMain
              }
            />
          </Box>

          <Box className={cn(s.historyBlock)}>
            {fetchingTransactions && <LoaderCentered />}
            {transactionsByCard[cardsOperationsPageSelectedCard.id]?.length >
              0 &&
              !fetchingTransactions && (
                <>
                  {transactionsByCard[cardsOperationsPageSelectedCard.id].map(
                    (transaction, index) => (
                      <Box
                        key={`${transaction.date_time}_${index}`}
                        className={cn(s.fullWidth)}
                      >
                        <p
                          className={cn(s.greySubTextSemiBold)}
                          style={{ fontSize: 16 }}
                        >
                          {transaction.date_time}
                        </p>
                        <HistoryItem
                          amount={Number(transaction.amount)}
                          currencyCode={"USDZ"}
                          title={
                            transaction.description ?? t("unnamedTransaction")
                            // "DNH*GODADDY.COM_AED____480-SSSSSSSS"
                          }
                          transaction={""}
                          fontSize={(transaction.description ?? t("unnamedTransaction")).length > 28 ? 11 : 14}
                          maxTitleLen={28}
                          imgSize={30}
                        />
                      </Box>
                    )
                  )}
                </>
              )}
            {!fetchingTransactions &&
              transactionsByCard[cardsOperationsPageSelectedCard.id]?.length ===
                0 && (
                <Box className={cn(s.fullSpaceCentered)}>
                  <p className={cn(s.greySubTextSemiBold)}>
                    {t("historyIsEmpty")}
                  </p>
                </Box>
              )}
          </Box>
        </Box>
      )}

      {cardsOperationsPageSelectedCard?.status === "Active" ? (
        <Box>
          {/* табы */}
          <TabsBar>
            {tabsData.map((tab) => (
              <Tab
                key={tab.id}
                active={activeTab === tab.id}
                onClick={() => onClickTab(tab)}
              >
                {tab.title}
              </Tab>
            ))}
          </TabsBar>

          <Line />
          {cardsOperationsPageSelectedCard ? (
            <ContentWrapper>
              {/* левая часть */}
              <SelectMethod
                {...{
                  boxesData,
                  setActiveBox,
                  activeTab,
                  activeBox,
                }}
              />
              {/* правая часть */}
              <RightWrapper>
                <MethodOptional
                  activePage={"cardsOperations"}
                  activeTab={activeTab}
                  activeBox={activeBox}
                />
              </RightWrapper>
            </ContentWrapper>
          ) : (
            <NoData text={t("payments.noCardSelected") as string} />
          )}
        </Box>
      ) : (
        <Box className={cn(s.cardStatusDescBox)}>
          <p
            className={cn(s.greySubTextSemiBold)}
            style={{ marginBottom: theme.metrics.x4 }}
          >
            {cardStatusDescriptionMessage}
          </p>
          {cardsOperationsPageSelectedCard?.status === "Rejected" && (
            <Box className={cn(s.cardStatusSpecialBtn)}>
              <CustomButton
                onPress={() => redirect()}
                title={t("buttons.tryAgain") as string}
                bgColorActive={theme.colors.yellowMain}
              />
            </Box>
          )}
          {cardsOperationsPageSelectedCard?.status === "Ready" && (
            <Box className={cn(s.cardStatusSpecialBtn)}>
              <CustomButton
                onPress={handleActivateCard}
                title={t("buttons.activate") as string}
                bgColorActive={theme.colors.yellowMain}
              />
            </Box>
          )}
        </Box>
      )}

      <ActivateCardModal
        visible={activateCardModalVisible}
        onClose={() => setActivateCardModalVisible(false)}
      />
    </Wrapper>
  );
};

CardsOperations.defaultProps = {};

export { CardsOperations };
