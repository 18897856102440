import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useStyles } from './OfflineWithdrawStyles';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { cryptoSelector } from '../../../../../logic/crypto/CryptoSelectors';
import { userSelector } from '../../../../../logic/user/UserSelectors';
import { generalSelector } from '../../../../../logic/general/GeneralSelectors';
import { useHideErrors } from '../../../../../hooks/useHideErrors';
import { useAmountInput } from '../../../../../hooks/useAmountInput';
import { useCountryText } from '../../../../../hooks/useCountryText';
import { UserActions } from '../../../../../logic/user/UserRedux';
import { CryptoActions } from '../../../../../logic/crypto/CryptoRedux';
import { GeneralActions } from '../../../../../logic/general/GeneralRedux';
import { validate2FACode, validateBIC, validateBankAccountNumber, validateBankName, validateFloat, validateIBAN } from '../../../../../helpers/validationHelper';
import { CURRENCY_DATA, CURRENCY_LABEL } from '../../../../../data/currency';
import { WalletItem } from '../../../../../components/WalletItem';
import { CONFIG } from '../../../../../config';
import { prettyStringToNumber, stringOrFallback } from '../../../../../helpers/formatHelpers';
import { useThemeContext } from '../../../../../theme';
import { CustomButton } from '../../../../../components/CustomButton';
import { TextInputUnderlined } from '../../../../../components/TextInputUnderlined';
import { StringValueItem } from '../../../../../components/StringValueItem';
import { SettingsItem } from '../../../../../components/SettingsItem';
import { ShowDetails } from '../../../../../components/ShowDetails';
import { VerificationRequired } from '../../../../../components/VerificationRequired';
import { useDependentValue, useSearchValue } from '../../../../../helpers/customHooks';
import { useVerified } from '../../../../../hooks/useVerified';
import { Bank, DataPickerItemType, LocalBank } from '../../../../../types/types';
import cn from 'classnames';
import { foundСoincidences } from '../../../../../helpers/strings';

export type OfflineWithdrawProps = {};

const OfflineWithdraw: React.FC<OfflineWithdrawProps> = ({}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const dispatch = useDispatch();

  const {
    remit: {
      fetching: fetchingRemit
    },
    paymentsPageSelectedAccount: accountData
  } = useSelector(cryptoSelector);

  const {
    whoAmI: {
      data: profile,
      fetching: fetchingProfile
    }
  } = useSelector(userSelector);

  const {
    localBanks: {
      data: localBanks,
      fetching: fetchingLocalBanks
    },
    searchBanks: {
      data: foundBanks,
      fetching: fetchingSearchBanks
    },
    swiftCountries: {
      data: swiftCountries
    }
  } = useSelector(generalSelector);

  const WITHDRAW_TYPE_DATA = useMemo(() => [
    {
      value: "local",
      label: t("offlineWithdrawPage.localBankTransfer"),
    },
    {
      value: "international",
      label: t("offlineWithdrawPage.internationalBankTransfer"),
    },
  ], [t]);

  const WITHDRAW_TYPE_LABEL = useMemo(() => ({
    "local": t("offlineWithdrawPage.localBankTransfer"),
    "international": t("offlineWithdrawPage.internationalBankTransfer"),
  }), []);

  const [withdrawType, setWithdrawType] = useState<"local" | "international">(WITHDRAW_TYPE_DATA[0].value as "local");
  const [currency, setCurrency] = useState(CURRENCY_DATA[0].value);
  const [bankName, bankNameInner, setBankNameInner] = useSearchValue("");
  const [selectedBankId, setSelectedBankId] = useState(-1);
  const [bic, setBic] = useState("");
  const [iban, setIban] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [message, setMessage] = useState("");
  const [code2FA, setCode2FA] = useState("");

  const userFullyVerified = useVerified();
  
  const {
    hideErrors,
    setHideErrors,
    onInputErrorAnimationEnd
  } = useHideErrors();

  const {
    amount,
    setAmount,
    handleAmountInput
  } = useAmountInput();

  const countryText = useCountryText(profile);

  const swiftCountryId = useDependentValue(() => {
    if (!profile?.country || !swiftCountries)
      return "";
    
    const swiftCountryData = swiftCountries.countries.find((c) => c.Country_Code === profile.country?.alpha_2);
    if (!swiftCountryData)
      return "";

    return swiftCountryData.id.toString();
  }, [profile, swiftCountries]);

  const bankVariantsData = useDependentValue(() => {
    if (withdrawType === "local") 
      return (localBanks ?? []).filter((bank) => foundСoincidences(bank.bank_name, bankName)).map((bank) => ({...bank, label: bank.bank_name, value: bank.id}));

    return (foundBanks ?? []).map((bank) => ({...bank, label: bank.bank, value: bank.id}));
  }, [withdrawType, foundBanks, localBanks, bankName]);

  const handleRequestWithdrawal = () => {
    if (!profile || !accountData)
      return;

    if (validateFloat(amount) && validateBankName(bankName) && validateBIC(bic) && validateIBAN(iban) && validateBankAccountNumber(accountNumber) && validate2FACode(code2FA)) {
      dispatch(CryptoActions.remit.request({
        from_account_id: accountData.id,
        amount: Number(amount),
        type: "Withdraw_Requested",
        otp_code: code2FA,
        bank_transfer: {
          country: profile.country?.name ?? "",
          // @ts-expect-error
          currency: CURRENCY_LABEL[currency],
          payment_type: "Wire Transfer",
          receiver_type: "Personal",
          full_name: `${profile.first_name} ${profile.last_name}`,
          mobile: profile.mobile,
          email: profile.email,
          reg_number: accountNumber,
          phone: "",
          bank_name: bankName,
          branch_name: "",
          BIC: bic,
          IBAN: iban,
          message,
          proforma: ""
        }
      }));
    } else {
      setHideErrors(false);
    }
  }

  const handleUserSelectedBank = useCallback((bank: DataPickerItemType & (Bank | LocalBank)) => {
    setSelectedBankId(bank.value as number);
    setBankNameInner(bank.label);

    if ('swift_code' in bank) {
      setBic(bank.swift_code);
    }
  }, [])

  // ComponentDidMount
  useEffect(() => {
    dispatch(GeneralActions.swiftCountries.request());
  }, []);

  useEffect(() => {
    if (!profile)
      dispatch(UserActions.whoAmI.request());
  }, [profile]);

  useEffect(() => {
    if (bankName && swiftCountryId)
      dispatch(GeneralActions.searchBanks.request({
        swift_country_id: swiftCountryId,
        query: bankName
      }));
  }, [bankName, swiftCountryId]);

  useEffect(() => {
    if (!localBanks && withdrawType === "local" && profile?.country?.id !== undefined)
      dispatch(GeneralActions.localBanks.request({
        country_id: profile.country.id.toString(),
        currency_code: currency
      }));
  }, [profile, withdrawType, localBanks, currency]);

  if (!userFullyVerified)
    return <VerificationRequired />;

  if (!profile || fetchingProfile || !accountData)
    return null;

  return (
    <Box className={cn(s.globalContainer)}>
      <Box className={cn(s.container)}>
        <Box className={cn(s.spaceBetween)}>
          <Box className={cn(s.topBlock)}>

            <p className={cn(s.whiteTextSemiBold)}>
              {t('from')}
            </p>
            
            <WalletItem 
              image={`${CONFIG.ZED_BASE_URL}/${accountData.currency_flag}`}
              userHasThisWallet={true}
              // userCurrencySymbol={getCurrencySymbol(userCurrency)}
              userCurrencySymbol="$"
              balance={accountData.balance * prettyStringToNumber(accountData.currency_price)}
              cryptoBalance={accountData.balance}
              name={accountData.currency_code}
              walletName={accountData.name}
              marginVertical={theme.metrics.x3}
            />
            
            <Box className={cn(s.inputsContainer)}>
              <TextInputUnderlined
                value={amount} 
                placeholder={t("amount") as string}
                onChangeText={(text) => handleAmountInput(text, setAmount)} 
                validationOk={hideErrors || validateFloat(amount)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />

              <StringValueItem 
                label={t("country")}
                value={countryText}
              />

              <Box className={cn(s.dataPicker)}>
                <SettingsItem
                  label={t('сurrency')}
                  labelColor={theme.colors.greySubText}
                  // @ts-expect-error
                  rightLabel={CURRENCY_LABEL[currency]}
                  onValueChange={v => setCurrency(v as any)}
                  picker={true}
                  items={CURRENCY_DATA}
                  value={currency}
                  rLabelMarginRight={0}
                  rLabelColor={theme.colors.yellowMain}
                  paddingLeft={0}
                  paddingRight={0}
                  enableOnhover={false}
                />
              </Box>

              <StringValueItem 
                label={t("profileDetailsPage.firstName")}
                value={profile.first_name}
              />
              <StringValueItem 
                label={t("profileDetailsPage.lastName")}
                value={profile.last_name}
              />
              <StringValueItem 
                label={t("profileDetailsPage.phoneNumber")}
                value={stringOrFallback(profile.mobile)}
              />
              <StringValueItem 
                label={t("profileDetailsPage.email")}
                value={stringOrFallback(profile.email)}
              />

              <SettingsItem
                label={`${t("type")}:`}
                labelColor={theme.colors.greySubText}
                // @ts-ignore
                rightLabel={WITHDRAW_TYPE_LABEL[withdrawType]}
                onValueChange={(v) => setWithdrawType(v as any)}
                picker={true}
                items={WITHDRAW_TYPE_DATA}
                value={withdrawType}
                paddingLeft={0}
                paddingRight={0}
                rLabelMarginRight={0}
                rLabelColor={theme.colors.yellowMain}
                enableOnhover={false}
              />

              <TextInputUnderlined
                value={bankNameInner}
                placeholder={t('offlineWithdrawPage.bankName') as string}
                onChangeText={text => setBankNameInner(text)}
                validationOk={hideErrors || validateBankName(bankName)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
                picker={true}
                items={bankVariantsData}
                pickerValue={selectedBankId}
                onPickerValueChange={(v) => handleUserSelectedBank(v as any)}
                loadingPickerItems={withdrawType === "local" && fetchingLocalBanks || withdrawType === "international" && fetchingSearchBanks}
              />
              <>
                <TextInputUnderlined
                  value={bic}
                  placeholder={"BIC"}
                  onChangeText={text => setBic(text)}
                  validationOk={hideErrors || validateBIC(bic)}
                  onErrorAnimationEnd={onInputErrorAnimationEnd}
                />
                <TextInputUnderlined
                  value={iban}
                  placeholder={"IBAN"}
                  onChangeText={text => setIban(text)}
                  validationOk={hideErrors || validateIBAN(iban)}
                  onErrorAnimationEnd={onInputErrorAnimationEnd}
                />
              </>
              <TextInputUnderlined
                value={accountNumber}
                placeholder={t('offlineWithdrawPage.accountNumber') as string}
                onChangeText={text => setAccountNumber(text)}
                validationOk={hideErrors || validateBankAccountNumber(accountNumber)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />
              <TextInputUnderlined
                value={message}
                placeholder={t("offlineWithdrawPage.message") as string}
                onChangeText={text => setMessage(text)}
                validationOk={true}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />

              <TextInputUnderlined
                value={code2FA} 
                placeholder={t("twoFACode") as string}
                onChangeText={(text) => setCode2FA(text)} 
                validationOk={hideErrors || validate2FACode(code2FA)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />

              <ShowDetails marginBottom={0}>
                <StringValueItem 
                  label={t("payments.operationTime")}
                  value={t("defaultConfirmOperationTime")}
                />
              </ShowDetails>
            </Box>
          </Box>

          <CustomButton
            title={t("offlineWithdrawPage.requestWithdrawal") as string}
            bgColorActive={theme.colors.yellowMain}
            colorActive={theme.colors.black}
            defaultSizing
            onPress={handleRequestWithdrawal}
            spinner={fetchingRemit}
          />
        </Box>
      </Box>
    </Box>
  );
};

OfflineWithdraw.defaultProps={}

export { OfflineWithdraw };