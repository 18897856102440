import { restActionCreatorHelper } from './../../helpers/restActionCreatorHelper';

const generalRestAction = restActionCreatorHelper(`general`);

export const ListCountriesRestActions = generalRestAction('listCountries');
export const ListDocumentsRestActions = generalRestAction('listDocument');
export const CurrencyListToOpenRestActions = generalRestAction('currencyListToOpen');
export const CurrencyListRestActions = generalRestAction('currencyList');
export const UploadFileRestActions = generalRestAction('uploadFile');
export const GenerateQRCodeRestActions = generalRestAction('generateQRCode');
export const FaqRestActions = generalRestAction('faq');
export const AboutRestActions = generalRestAction('about');
export const GetBankAccountsRestActions = generalRestAction('getBankAccounts');
export const SearchBanksRestActions = generalRestAction('searchBanks');
export const LocalBanksRestActions = generalRestAction('localBanks');
export const SwiftCountriesRestActions = generalRestAction('swiftCountries');
export const CommissionsRestActions = generalRestAction('commissions');