import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/default/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 350,
    minWidth: 350,
    backgroundColor: theme.colors.black,
    alignSelf: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.metrics.x2,
    height: 200,
    marginRight: theme.metrics.x4,
    position: 'relative',
    overflow: 'hidden'
  },
  topPart: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.metrics.x4,
    paddingLeft: theme.metrics.x4,
    paddingBottom: theme.metrics.x3,
  },
  blurCont: {
    display: 'flex',
    paddingLeft: theme.metrics.x2,
    paddingRight: theme.metrics.x2,
    paddingTop: theme.metrics.x3_5,
    paddingBottom: theme.metrics.x3_5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.blackOpacityBackground
  },
  blurText: {
    fontFamily: theme.fonts.f400.fontFamily,
    letterSpacing: -0.3,
    fontSize: theme.metrics.x3,
    color: theme.colors.white,
    maxWidth: '70%',
    margin: 0
  },
  subTitle: {
    fontFamily: theme.fonts.f600.fontFamily,
    letterSpacing: -0.3,
    fontSize: theme.metrics.x4,
    color: theme.colors.white,
    margin: 0
  },
  title: {
    fontFamily: theme.fonts.f700.fontFamily,
    letterSpacing: -0.3,
    fontSize: theme.metrics.x5_5,
    maxWidth: '70%',
    color: theme.colors.white,
    marginLeft: 0,
    marginTop: theme.metrics.x1_5,
    marginRight: 0,
    marginBottom: 0
  },
  tagCont: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.metrics.x2,
    paddingRight: theme.metrics.x2,
    paddingTop: theme.metrics.x1_5,
    paddingBottom: theme.metrics.x1_5,
    marginTop: theme.metrics.x2,
    border: `1px solid ${theme.colors.white}`,
    borderRadius: theme.metrics.x1_5,
    alignSelf: 'flex-start',
  },
  tag: {
    fontFamily: theme.fonts.f600.fontFamily,
    letterSpacing: -0.3,
    fontSize: theme.metrics.x3,
    color: theme.colors.white,
    margin: 0
  },
  image: {
    display: 'flex',
    height: 100,
    width: 100,
    position: 'absolute',
    right: 5,
    top: 1,
    objectFit: 'contain',
    // backgroundColor: 'red'
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    paddingLeft: theme.metrics.x2_5,
    paddingRight: theme.metrics.x2_5,
    paddingTop: theme.metrics.x1_5,
    paddingBottom:  theme.metrics.x1_5,
    backgroundColor: theme.colors.white,
    borderRadius: theme.metrics.x2,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  buttonText: {
    fontFamily: theme.fonts.f600.fontFamily,
    letterSpacing: -0.3,
    fontSize: theme.metrics.x3,
    color: theme.colors.black,
    margin: 0
  },
  marginVertical: {
    marginTop: theme.metrics.x2,
    marginBottom: theme.metrics.x2,
  }
});