import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
} from "@reduxjs/toolkit";
import {
  addAllRestReducers,
  addRestReducers,
  createRestActions,
  getDefaultRestState,
} from "../../store/restHelper";
import { NodeRestStateType } from "../../store/restHelper.d";
import {
  ChangeAvatarRestActions,
  ChangePasswordRestActions,
  ConfirmNewEmailRestActions,
  ConfirmPhoneRestActions,
  DisableAuthenticatorCodeRestActions,
  EditProfileRestActions,
  GetAuthenticatorCodeRestActions,
  IntercomUserHashRestActions,
  LoginRestActions,
  LogoutRestActions,
  RegisterEmailRestActions,
  RegisterRestActions,
  RequestResetPasswordRestActions,
  ResetPasswordRestActions,
  SendDocumentsRestActions,
  SetNotificationsRestActions,
  VerifyAuthenticatorCodeRestActions,
  VerifyPhoneRestActions,
  WhoAmIRestActions,
} from "./UserActions";
import fp from "lodash/fp";
import {
  ChangeAvatarRequest,
  ConfirmMobileRequest,
  EditProfileRequest,
} from "../../types/api";
import {
  BusinessInfo,
  Country,
  ZedApiKey,
  ZedApiNotificationInfo,
  PlatformBackendT,
} from "../../types/types";
import { IntercomProps } from "react-use-intercom";

export const ERROR_MESSAGE_2FA_REQUIRED = "OTP is not correct.";

export type ZedAPIDefaultResponse<V = any, T = {}> = {
  id: number;
  success: boolean;
  message: string;
  value: V;
} & T;

export type IntercomUserHashResponse = string;

export type GetAuthenticatorCodeResponse = ZedAPIDefaultResponse<{
  AuthenticationCode: string;
  AuthenticationBarCodeImage: string;
  AuthenticationManualCode: string;
  success: boolean;
}>;

export type UserInfo = {
  // External part
  user_id: number;
  user_type: number;
  first_name: string;
  last_name: string;
  gender: string;
  email: string;
  mobile: string;
  avatar: string;
  access: number[];
  notification: ZedApiNotificationInfo;
  otp_enabled: boolean;
  status: string;
  firebase_id: string | null;

  // External additional part
  agent_id?: number;
  reg_date?: string;
  birth_date?: string;
  customer_type?: string;
  phone?: string;
  country?: Country;
  city?: string;
  address?: string;
  remark?: string;
  documents?: any;
  business_info?: BusinessInfo;
  api_key?: ZedApiKey;

  // Iternal part
  id: string;
  role: string;
  apperDate: number;
};

export type ActionModalState = {
  visible: boolean;
  title?: string;
  text?: string;
  yesBtnText?: string;
  noBtnText?: string;
  onPressYes: () => void;
  onPressNo?: () => void;
} | null;

type RegisterEmailPayload = {
  email: string;
  platform: PlatformBackendT;
  agent_id?: number;
  successCallback?: () => void;
};

type ConfirmNewEmailPayload = {
  email: string;
  code: string;
  successCallback?: () => void;
  wrongCodeCallback?: () => void;
};

type RegisterPayload = {
  first_name: string;
  last_name: string;
  customer_type: number;
  email: string;
  password: string;
  country_id: number;
  referral_code?: string;
  successCallback?: () => void;
};

type LoginResponse = {
  access_token: string;
  token_type: string;
};

type LoginPayload = {
  email: string;
  password: string;
  otp?: string;
  platform: string;
  successCallback?: () => void;
};

type WhoAmIPayload =
  | {
      fetchAdditionalInfoOnSuccess: boolean;
    }
  | undefined;

type LogoutPayload = {
  deletePasscodeFromLocalStorage?: boolean;
};

type ChangePasswordPayload = {
  current_password: string;
  new_password: string;
  delete_tokens: boolean;
  otp_code?: string;
};

type RequestResetPasswordPayload = {
  email: string;
  successCallback?: () => void;
};

type ResetPasswordPayload = {
  code: string;
  new_password: string;
};

type VerifyAuthenticatorCodePayload = {
  otp: string;
};

type DisableAuthenticatorCodePayload = {
  otp: string;
};

export type SendDocumentsPayload = {
  id: number;
  documents: { title: string; file: string }[];
};

export type SetNotificationsPayload = {
  id: number;
  notification: ZedApiNotificationInfo;
  manual?: boolean;
};

export type IntercomUserHashPayload = {
  profile: UserInfo;
};

const registerEmailRestActions = createRestActions<
  ZedAPIDefaultResponse,
  RegisterEmailPayload
>(RegisterEmailRestActions);

const confirmNewEmailRestActions = createRestActions<
  ZedAPIDefaultResponse,
  ConfirmNewEmailPayload
>(ConfirmNewEmailRestActions);

const registerRestActions = createRestActions<
  ZedAPIDefaultResponse,
  RegisterPayload
>(RegisterRestActions);

const loginRestActions = createRestActions<
  LoginResponse,
  LoginPayload
>(LoginRestActions);

const whoAmIRestActions = createRestActions<
  UserInfo,
  WhoAmIPayload
>(WhoAmIRestActions);

const changePasswordRestActions = createRestActions<
  ZedAPIDefaultResponse,
  ChangePasswordPayload
>(ChangePasswordRestActions);

const requestResetPasswordRestActions = createRestActions<
  ZedAPIDefaultResponse<{ code: string; reset_url: string }>,
  RequestResetPasswordPayload
>(RequestResetPasswordRestActions);

const resetPasswordRestActions = createRestActions<
  ZedAPIDefaultResponse,
  ResetPasswordPayload
>(ResetPasswordRestActions);

const getAuthenticatorCodeRestActions = createRestActions<
  GetAuthenticatorCodeResponse
>(GetAuthenticatorCodeRestActions);

const verifyAuthenticatorCodeRestActions = createRestActions<
  UserInfo,
  VerifyAuthenticatorCodePayload
>(VerifyAuthenticatorCodeRestActions);

const disableAuthenticatorCodeRestActions = createRestActions<
  UserInfo,
  DisableAuthenticatorCodePayload
>(DisableAuthenticatorCodeRestActions);

const logoutRestActions = createRestActions<
  void,
  LogoutPayload
>(LogoutRestActions);

const sendDocumentsRestActions = createRestActions<
  any,
  SendDocumentsPayload
>(SendDocumentsRestActions);

const verifyPhoneRestActions = createRestActions<
  void
>(VerifyPhoneRestActions);

const confirmPhoneRestActions = createRestActions<
  any,
  ConfirmMobileRequest
>(ConfirmPhoneRestActions);

const changeAvatarRestActions = createRestActions<
  ZedAPIDefaultResponse,
  ChangeAvatarRequest
>(ChangeAvatarRestActions);

const setNotificationsRestActions = createRestActions<
  void,
  SetNotificationsPayload
>(SetNotificationsRestActions);

const intercomUserHashRestActions = createRestActions<
  IntercomUserHashResponse,
  IntercomUserHashPayload
>(IntercomUserHashRestActions);

const editProfileRestActions = createRestActions<
  void,
  EditProfileRequest
>(EditProfileRestActions);

const UserRestActions = {
  registerEmail: registerEmailRestActions,
  confirmNewEmail: confirmNewEmailRestActions,
  register: registerRestActions,
  login: loginRestActions,
  logout: logoutRestActions,
  whoAmI: whoAmIRestActions,
  changePassword: changePasswordRestActions,
  requestResetPassword: requestResetPasswordRestActions,
  resetPassword: resetPasswordRestActions,
  getAuthenticatorCode: getAuthenticatorCodeRestActions,
  verifyAuthenticatorCode: verifyAuthenticatorCodeRestActions,
  disableAuthenticatorCode: disableAuthenticatorCodeRestActions,
  sendDocuments: sendDocumentsRestActions,
  verifyPhone: verifyPhoneRestActions,
  confirmPhone: confirmPhoneRestActions,
  editProfile: editProfileRestActions,
  changeAvatar: changeAvatarRestActions,
  setNotifications: setNotificationsRestActions,
  intercomUserHash: intercomUserHashRestActions,
}

const UserActions = {
  ...UserRestActions,
  storeTokenToRedux: createAction<UserStringInfo>("user/storeTokenToRedux"),
  storeUserIdToRedux: createAction<UserNumberInfo>("user/storeUserIdToRedux"),
  setUserPasscode: createAction<UserStringInfo>("user/setUserPasscode"),
  setUserEmail: createAction<UserStringInfo>("user/setUserEmail"),
  runInitialSaga: createAction("user/runInitialSaga"),
  setEnabled2FA: createAction<boolean>("user/setEnabled2FA"),
  setActionModalState: createAction<ActionModalState>(
    "user/setActionModalState"
  ),
  clearWhoAmI: createAction('user/clearWhoAmI')
};

type UserStringInfo = undefined | string;
type UserNumberInfo = undefined | number;
type UserRestNodes = keyof typeof UserRestActions;

type UserStore = {
  userToken: UserStringInfo;
  userId: UserNumberInfo;
  userPasscode: UserStringInfo;
  userEmail: UserStringInfo;
  enabled2FA: boolean;
  actionModalState: ActionModalState;
};

const initialUserStore: UserStore = {
  userToken: undefined,
  userId: undefined,
  userPasscode: undefined,
  userEmail: undefined,
  enabled2FA: false,
  actionModalState: null,
};

const initialRestState = {
  login: getDefaultRestState(),
  logout: getDefaultRestState(),
  registerEmail: getDefaultRestState(),
  confirmNewEmail: getDefaultRestState(),
  register: getDefaultRestState(),
  whoAmI: getDefaultRestState<UserInfo>(),
  changePassword: getDefaultRestState(),
  requestResetPassword: getDefaultRestState(),
  resetPassword: getDefaultRestState(),
  getAuthenticatorCode: getDefaultRestState<GetAuthenticatorCodeResponse>(),
  verifyAuthenticatorCode: getDefaultRestState(),
  disableAuthenticatorCode: getDefaultRestState(),
  sendDocuments: getDefaultRestState(),
  verifyPhone: getDefaultRestState(),
  confirmPhone: getDefaultRestState(),
  editProfile: getDefaultRestState(),
  changeAvatar: getDefaultRestState(),
  setNotifications: getDefaultRestState(),
  intercomUserHash: getDefaultRestState<IntercomUserHashResponse>()
};

type UserState = NodeRestStateType<
  UserRestNodes,
  UserStore & typeof initialRestState
>;

type Builder = ActionReducerMapBuilder<UserState>;

const userReducer = createReducer(
  { ...initialUserStore, ...initialRestState },
  (builder) =>
    (
      fp.flow(addAllRestReducers<typeof UserRestActions>(UserRestActions))(builder) as Builder
    )
      .addCase(UserActions.storeTokenToRedux, (state, action) => {
        state.userToken = action.payload;
      })
      .addCase(UserActions.storeUserIdToRedux, (state, action) => {
        state.userId = action.payload;
      })
      .addCase(UserActions.setUserPasscode, (state, action) => {
        state.userPasscode = action.payload;
      })
      .addCase(UserActions.setUserEmail, (state, action) => {
        state.userEmail = action.payload;
      })
      .addCase(UserActions.setEnabled2FA, (state, action) => {
        state.enabled2FA = action.payload;
      })
      .addCase(UserActions.setActionModalState, (state, action) => {
        state.actionModalState = action.payload;
      })
      .addCase(UserActions.clearWhoAmI, (state) => {
        state.whoAmI = getDefaultRestState<UserInfo>();
      })
);

export { userReducer, UserActions };
