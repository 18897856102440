import React, { memo, useState } from "react";
import { useStyles } from "./WalletItemStyles";
import { Box } from "@material-ui/core";
import { getAdaptivePriceFormatter } from "../../helpers/price";
import { theme } from "../../theme/default/theme";
import { CustomButton } from "../CustomButton";
import cn from "classnames";
import currency from "currency.js";
import { stringWithMaxLen } from "../../helpers/strings";

export type WalletItemProps = {
  image: string;
  imageObjectFit?: string;
  userCurrencySymbol?: string;
  balance: number;
  cryptoBalance: number;
  name: string;
  walletName?: string;
  actionButtonTitle?: string;
  actionButtonDisabled?: boolean;
  userHasThisWallet?: boolean;
  paddingLeft?: number;
  paddingRight?: number;
  marginVertical?: number;
  fetchingAction?: boolean;
  rightLabel?: string;
  rightLabelColor?: string;
  alt?: string;
  rightLabelMarginRight?: number;
  onPress?: () => void;
  onPressAction?: () => void;
};

const WalletItem: React.FC<WalletItemProps> = ({
  image,
  imageObjectFit,
  userCurrencySymbol,
  balance,
  cryptoBalance,
  name,
  walletName,
  actionButtonTitle,
  actionButtonDisabled,
  userHasThisWallet,
  paddingLeft,
  paddingRight,
  marginVertical,
  fetchingAction,
  rightLabel,
  rightLabelColor,
  alt,
  rightLabelMarginRight,
  onPress,
  onPressAction,
}) => {
  const s = useStyles();

  const balanceFormatted = currency(
    balance,
    getAdaptivePriceFormatter(balance, userCurrencySymbol)
  ).format();
  const cryptoBalanceFormatted = currency(
    cryptoBalance,
    getAdaptivePriceFormatter(cryptoBalance, "")
  ).format();
  return (
    <Box
      className={cn(s.container, { [s.pointer]: !!onPress })}
      style={{
        paddingLeft,
        paddingRight,
        marginTop: marginVertical,
        marginBottom: marginVertical,
      }}
      onClick={() => onPress && onPress()}
      title={alt}
    >
      <Box
        className={cn(s.left)}
        style={{ width: onPressAction ? "75%" : "100%" }}
      >
        <Box className={cn(s.logoCont)}>
          <img
            src={image}
            className={cn(s.fullSpace)}
            style={{ objectFit: imageObjectFit as any }}
            alt="logo cont"
          />
        </Box>
        <Box>
          <p className={cn(s.whiteBold)}>{balanceFormatted}</p>
          <p className={cn(s.greyText)}>
            {stringWithMaxLen(`${userHasThisWallet ? cryptoBalanceFormatted + " " : ""}${name} ${walletName ? `(${walletName})` : ""}`, 32)}
          </p>
        </Box>
      </Box>

      {(onPressAction || rightLabel) && (
        <Box className={cn(s.right)}>
          {onPressAction ? (
            <Box className={cn(s.actionButton)}>
              <CustomButton
                title={actionButtonTitle}
                borderWidth={1}
                bgColorActive={theme.colors.transparent}
                colorActive={theme.colors.white}
                bgColorUnactive={theme.colors.transparent}
                borderColorActive={theme.colors.yellowMain}
                borderColorUnactive={theme.colors.borderGrey}
                onPress={onPressAction}
                spinner={fetchingAction}
                disabled={actionButtonDisabled}
              />
            </Box>
          ) : (
            <p className={cn(s.rightLabel)} style={{ color: rightLabelColor, marginRight: rightLabelMarginRight }}>
              {rightLabel}
            </p>
          )}
        </Box>
      )}
    </Box>
  );
};

WalletItem.defaultProps = {
  userCurrencySymbol: "$",
  actionButtonTitle: "Action",
  actionButtonDisabled: false,
  fetchingAction: false,
  userHasThisWallet: false,
  imageObjectFit: "cover",
  rightLabelColor: theme.colors.yellowMain,
};

const MemorizedComponent = memo(WalletItem);
export { MemorizedComponent as WalletItem };
