import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "./../../theme";

export const createStyle = (theme: Theme) =>
  makeStyles({
    container: {
      display: "flex",
      flex: 1,
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#475678",
    },
    whiteText: {
      color: theme.colors.white,
      fontFamily: theme.fonts.f400.fontFamily,
    },
    center: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    button: {
      marginTop: 20,
    },
  })();
