import React, { memo, useState } from 'react';
import { useStyles } from './LoadCardStyles';
import { Box } from '@material-ui/core';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../../../../components/CustomButton';
import { useThemeContext } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { cardsSelector } from '../../../../../logic/cards/CardsSelectors';
import { useDependentValue } from '../../../../../helpers/customHooks';
import { useHideErrors } from '../../../../../hooks/useHideErrors';
import { useAmountInput } from '../../../../../hooks/useAmountInput';
import { validate2FACode, validateFloat } from '../../../../../helpers/validationHelper';
import { CardsActions } from '../../../../../logic/cards/CardsRedux';
import { StringValueItem } from '../../../../../components/StringValueItem';
import { formatCardNumberWithSpaces } from '../../../../../helpers/formatHelpers';
import { TextInputUnderlined } from '../../../../../components/TextInputUnderlined';
import { ShowDetails } from '../../../../../components/ShowDetails';
import currency from 'currency.js';
import { getAdaptivePriceFormatter } from '../../../../../helpers/price';

export type LoadCardProps = {};

const LoadCard: React.FC<LoadCardProps> = ({}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const dispatch = useDispatch();

  const {
    cardsOperationsPageSelectedCard: card,
    loadVirtualCard: {
      fetching: fetchingLoadVirtualCard
    },
    loadPhysicalCard: {
      fetching: fetchingLoadPhysicalCard
    },
    cardCommissions: {
      data: cardCommissions
    }
  } = useSelector(cardsSelector);

  const [code2FA, setCode2FA] = useState("");

  const fetchingLoadCard = useDependentValue(() => (fetchingLoadVirtualCard || fetchingLoadPhysicalCard), [fetchingLoadVirtualCard, fetchingLoadPhysicalCard]);

  const {
    hideErrors,
    setHideErrors,
    onInputErrorAnimationEnd
  } = useHideErrors();

  const {
    amount,
    setAmount,
    handleAmountInput
  } = useAmountInput();

  const currentCardBalancePretty = useDependentValue(() => {
    const balance = card?.balance ?? 0;
    return currency(balance, getAdaptivePriceFormatter(balance, "$")).format();
  }, [card]);

  const cardBalanceAfterOperationPretty = useDependentValue(() => {
    const balance = card?.balance ?? 0;
    const amountTopUp = Number(amount);
    const balanceAfterOperation = balance + amountTopUp;
    return currency(balanceAfterOperation, getAdaptivePriceFormatter(balanceAfterOperation, "$")).format();
  }, [card, amount]);

  const operationFee = useDependentValue(() => {
    if (!cardCommissions)
      return 20;

    // @ts-ignore
    return cardCommissions.commissions[`${card.card_type.toLowerCase()}_load`] ?? 20;
  }, [card, cardCommissions]);

  const onPressTopUp = () => {
    if (!card)
      return ;
      
    if (validate2FACode(code2FA) && validateFloat(amount)) {
      const action = card.card_type === "Virtual" ? CardsActions.loadVirtualCard.request : CardsActions.loadPhysicalCard.request;
      dispatch(action({
        id: card.id,
        amount: Number(amount),
        otp_code: code2FA
      }));
    } else {
      setHideErrors(false);
    }
  };

  if (!card)
    return null;

  return (
    <Box className={cn(s.globalContainer)}>
      <Box className={cn(s.container)}>
        <Box className={cn(s.spaceBetween)}>
          <Box className={cn(s.topBlock)}>
            <Box className={cn(s.inputsContainer)}>
              <StringValueItem 
                label={t("cardNumber")}
                value={formatCardNumberWithSpaces(card.card_number)}
              />

              <TextInputUnderlined
                value={amount} 
                placeholder={t("amount") as string}
                onChangeText={(text) => handleAmountInput(text, setAmount)} 
                validationOk={hideErrors || validateFloat(amount)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />

              <TextInputUnderlined
                value={code2FA} 
                placeholder={t("twoFACode") as string}
                onChangeText={(text) => setCode2FA(text)} 
                validationOk={hideErrors || validate2FACode(code2FA)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />

              <ShowDetails marginBottom={0}>
                <StringValueItem 
                  label={t("payments.currentCardBalance")}
                  value={currentCardBalancePretty}
                />
                <StringValueItem 
                  label={t("payments.cardBalanceAfterOperation")}
                  value={cardBalanceAfterOperationPretty}
                />
                <StringValueItem 
                  label={t("payments.operationTime")}
                  value={t("payments.instantly")}
                />
                <StringValueItem 
                  label={t("operationFee")}
                  value={`${currency(operationFee, getAdaptivePriceFormatter(operationFee, "")).format()} USDZ`}
                />
              </ShowDetails>
            </Box>
          </Box>

          <CustomButton
            title={t("buttons.topUp") as string}
            bgColorActive={theme.colors.yellowMain}
            colorActive={theme.colors.black}
            defaultSizing
            spinner={fetchingLoadCard}
            onPress={onPressTopUp}
          />
        </Box>
      </Box>
    </Box>
  );
};

LoadCard.defaultProps={}

const MemorizedComponent = memo(LoadCard);
export { MemorizedComponent as LoadCard };