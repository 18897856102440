import { restActionCreatorHelper } from "./../../helpers/restActionCreatorHelper";

const cryptoRestAction = restActionCreatorHelper(`crypto`);

export const GetAccountsListRestActions = cryptoRestAction("getAccountsList");

export const OpenAccountRestActions = cryptoRestAction("openAccount");

export const DepositRestActions = cryptoRestAction("deposit");

export const BuyCryptoElementsRestActions =
  cryptoRestAction("buyCryptoElements");

export const DeleteAccountRestActions = cryptoRestAction("deleteAccount");

export const ChangeAccountStatusRestActions = cryptoRestAction(
  "changeAccountStatus"
);

export const TransferRestActions = cryptoRestAction("transfer");

export const CoingeckoMarketChartsRestActions = cryptoRestAction(
  "coingeckoMarketCharts"
);

export const ConvertCurrencyRestActions = cryptoRestAction("convertCurrency");

export const RemitRestActions = cryptoRestAction("remit");

export const SwapReceiveRestActions = cryptoRestAction("swapReceive");