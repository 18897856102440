import React, { useEffect, useState } from "react";
import {
  AgreeTerms,
  BoxWBorder,
  ErrorText,
  FormContainer,
  FormTitle,
  ImageArrow,
  LeftWrapper,
  LeftWrapperRes,
  RightWrapper,
  Skip,
  Slider,
  SmallLink,
  SmallText,
  Text,
  Title,
  Wrapper,
} from "./LoginPageStyles";
import { Input } from "../../components/Input/Input";
import { Btn } from "../../components/Button/Btn";
import { Checkbox } from "@mui/material";
import { useThemeContext } from "../../theme";
import { ContentBox } from "../../components/ContentBox/ContentBox";
import { sliderData } from "./Slider/data";
import { useAuth } from "./hook";
import { SupportButton } from "../../components/SupportButton";
import { useNavigate } from "react-router-dom";
import { Box } from "@material-ui/core";
import i18n from "../../i18n/i18n";
import SliderLogin from "./Slider";
import OtpBlock from "./Otp";
import "swiper/swiper.min.css";
import useEnterKeyPress from "../../hooks/useEnterKeyPress";

export type LoginPageProps = {};

const { t } = i18n;

export type SliderItem = {
  title: string;
  text: string;
  img: string;
};

const LoginPage: React.FC<LoginPageProps> = ({}) => {
  const { theme } = useThemeContext();
  const navigate = useNavigate();
  const [buttonIsActive, setButtonIsActive] = useState(false);

  const {
    password,
    email,
    code2FA,
    skip,
    errors,
    checked,
    swiperRef,
    swiperRef2,
    pageStep,
    activeSlideIndex,
    fetchingLogin,
    confirm,
    setSkip,
    inputHandler,
    setChecked,
    switchModeHandle,
    handlePrevSlide,
    handleNextSlide,
    handleSlideChange,
    fetchingRegisterEmail,
    enabled2FA,
  } = useAuth();

  useEffect(() => {
    setButtonIsActive(
      pageStep === "login" ? !!email && !!password : !!email && checked
    );
  }, [pageStep, email, password, checked]);

  useEnterKeyPress(buttonIsActive, confirm);

  return (
    <Wrapper>
      {/* десктоп */}
      <LeftWrapper skip={skip}>
        <Slider>
          <BoxWBorder onClick={() => handlePrevSlide()}>
            <img src="/images/Arrow.svg" alt="asd" />
          </BoxWBorder>

          <SliderLogin
            data={sliderData}
            activeSlideIndex={activeSlideIndex}
            handleSlideChange={handleSlideChange}
            swiperRef={swiperRef}
          />
          <BoxWBorder left={true} onClick={() => handleNextSlide()}>
            <img src="/images/Arrow.svg" alt="asd" />
          </BoxWBorder>
        </Slider>
        <Title>{t(sliderData[activeSlideIndex].title)}</Title>
        <Text>{t(sliderData[activeSlideIndex].text)}</Text>
      </LeftWrapper>
      {/* моб версия */}
      <LeftWrapperRes skip={skip}>
        <Skip onClick={() => setSkip(true)}>{t("loginPage.slider.skip")}</Skip>
        <Slider>
          <SliderLogin
            data={sliderData}
            activeSlideIndex={activeSlideIndex}
            handleSlideChange={handleSlideChange}
            swiperRef={swiperRef2}
          />
        </Slider>
        <Title>{t(sliderData[activeSlideIndex].title)}</Title>
        <Text>{t(sliderData[activeSlideIndex].text)}</Text>
        <ContentBox
          backColor="none"
          justyfiContent="space-between"
          width="100%"
          margin="32px 0 0 0"
        >
          <BoxWBorder onClick={() => handlePrevSlide()}>
            <ImageArrow src="/images/Arrow.svg" alt="asd" />
          </BoxWBorder>
          <BoxWBorder left={true} onClick={() => handleNextSlide()}>
            <ImageArrow src="/images/Arrow.svg" alt="asd" />
          </BoxWBorder>
        </ContentBox>
      </LeftWrapperRes>
      {/* правая часть */}
      <RightWrapper skip={skip}>
        {(pageStep === "login" || pageStep === "register") && (
          <FormContainer>
            <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <BoxWBorder>
                <img
                  style={{ width: "30px", height: "30px" }}
                  src="/images/flags/gb.svg"
                  alt="aas"
                  onClick={() => i18n.changeLanguage("de")}
                />
              </BoxWBorder>
              <SupportButton />
            </Box>
            <FormTitle>
              {pageStep === "login"
                ? t("loginPage.signIn")
                : t("loginPage.createAccount")}
            </FormTitle>
            <SmallText>
              {pageStep === "login"
                ? t("loginPage.or")
                : t("loginPage.alreadyHave")}
              <SmallLink
                onClick={() =>
                  switchModeHandle(pageStep === "login" ? "register" : "login")
                }
              >
                {pageStep === "login"
                  ? t("loginPage.createAccount")
                  : t("loginPage.signIn")}
              </SmallLink>
            </SmallText>
            <Input
              placeholderText={t("loginPage.email")}
              name="email"
              value={email}
              handler={inputHandler}
            />
            {pageStep === "login" && (
              <Input
                placeholderText={t("loginPage.password")}
                name="password"
                value={password}
                handler={inputHandler}
                eye={true}
                password={true}
              />
            )}
            {pageStep === "login" && enabled2FA && (
              <Input
                placeholderText={t("twoFACode")}
                name={"code2FA"}
                value={code2FA}
                handler={inputHandler}
              />
            )}
            {errors.map((el, index) => (
              <ErrorText key={index}>{el}</ErrorText>
            ))}
            {/* {pageStep === 'login' || (
            <Input
              placeholderText={t("loginPage.referralCode")}
              name="referralCode"
              value={inputsData.referralCode}
              handler={inputHandler}
            />
          )} */}
            {pageStep === "login" || (
              <AgreeTerms>
                <Checkbox
                  onChange={(e) => setChecked(e.target.checked)}
                  sx={{
                    color: theme.colors.yellowMain,
                    "&.Mui-checked": {
                      color: theme.colors.yellowMain,
                    },
                  }}
                />
                <SmallText>
                  {t("loginPage.terms1")}
                  <SmallLink>{t("loginPage.terms2")}</SmallLink>
                </SmallText>
              </AgreeTerms>
            )}

            {pageStep === "login" && (
              <div style={{ marginTop: theme.metrics.x2 }}>
                <SmallText>
                  {t("buttons.forgotPassword")}
                  <SmallLink onClick={() => navigate("/resetPassword")}>
                    {t("buttons.restore")}
                  </SmallLink>
                </SmallText>
              </div>
            )}

            <Btn
              type="authPage"
              buttonActive={buttonIsActive}
              spinner={
                (pageStep === "register" && fetchingRegisterEmail) ||
                (pageStep === "login" && fetchingLogin)
              }
              func={() => confirm()}
              label={
                pageStep === "login"
                  ? t("loginPage.signIn")
                  : t("loginPage.createAccount")
              }
            />
          </FormContainer>
        )}
        {pageStep === "otp" && <OtpBlock />}
      </RightWrapper>
    </Wrapper>
  );
};

LoginPage.defaultProps = {};

export { LoginPage };
