import { combineReducers } from "redux";
import { userReducer } from "../logic/user/UserRedux";
import { appSettingsReducer } from "../logic/appSettings/AppSettingsRedux";
import { generalReducer } from "../logic/general/GeneralRedux";
import { cryptoReducer } from "../logic/crypto/CryptoRedux";
import { socialReducer } from "../logic/social/SocialRedux";
import { storieReducer } from "../logic/storie/StorieRedux";
import { cardsReducer } from "../logic/cards/CardsRedux";
import { nftReducer } from "../logic/nft/NftRedux";

export const rootReducer = combineReducers({
  user: userReducer,
  appSettings: appSettingsReducer,
  general: generalReducer,
  social: socialReducer,
  crypto: cryptoReducer,
  storie: storieReducer,
  cards: cardsReducer,
  nft: nftReducer
});

export type RootState = ReturnType<typeof rootReducer>;