import styled, { css, keyframes } from "styled-components";

import { templates } from "../../../theme/templates";
import { text } from "../../../theme/text";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme/default/theme";
import { SIDEBAR_WIDTH } from "../layoutConstants";

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div<{mobile?:boolean}>`
  position: fixed;
  left: 0;
  overflow-y: scroll;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  width: ${SIDEBAR_WIDTH}px;
  min-height: 100vh;
  height: 100%;
  background-color: ${(p) => p.theme.colors.headerBlack};
  z-index: 6;
  @media (max-width: 1300px) {
    
    display: ${p => p.mobile ? 'flex' : 'none'};
    width: ${p => p.mobile ? 'inherit' : 'auto'};
    margin-top: ${p => p.mobile ? '-20px' : '0'};
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Head = styled.div`
  ${templates.centerContent};
  column-gap: 25px;
  color: ${(p) => p.theme.colors.white};
  font-weight: 600;
  font-size: 26px;
  line-height: 37px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
`;
export const Logo = styled.img`
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
`;
export const Line = styled.div`
  width: calc(100% + 48px);
  margin: 16px -24px 0;
  height: 2px;
  background-color: #24282b;
`;
export const BoxWBorder = styled.div`
  ${templates.centerContent}
  height: 38px;
  border: 1px solid ${p => p.theme.colors.black};
  border-radius: 10px;
  width: 60px;
  cursor: pointer;
  background-color: ${p => p.theme.colors.yellowMain};
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
`;
// curencyItem
export const CurrencyItemsBar = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  row-gap: 24px;
`;
export const CurrencyItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const CurrencyItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
export const StateArrow = styled.img`
  margin-left: auto;
`;
// loadings
export const LoadingWallets = styled.div`
padding-left: 5px;
display: flex;
align-items: center;

width: 100%;
height: 60px;
`;
export const LoadingMarketChart = styled.div`
display: flex;
flex-direction: column;
row-gap: 15px;
margin-top: 15px;

`;
export const ReloadIcon = styled.img<{fetching: boolean}>`
width: 30px;
height: 30px;
margin: auto 0 12px 0;
cursor: pointer;
${(props) =>
    props.fetching &&
    css`
      animation: ${spinAnimation} 1s linear infinite; 
    `}
`;

export const useStyles = makeStyles({
  marketCap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: theme.metrics.x4,
    paddingBottom: theme.metrics.x4,
    borderTop: `1px solid ${theme.colors.borderGrey}`,
    borderBottom: `1px solid ${theme.colors.borderGrey}`,
  },
  fullWidthItemContainer: {
    display: "flex",
    width: '100%',
    borderRadius: 8,
    paddingLeft: 8
  },
  fullWidthItemContainerSelected: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    // paddingLeft: 8
  },
  marginTop: {
    display: "flex",
    width: '100%',
    marginTop: theme.metrics.x4
  }
});
export const LogoContainer = styled.div`
position: relative;
`;
export const LogoCoverIconCOntainer = styled.div`
position: absolute;
display: flex;
background-color: ${p => p.theme.colors.headerBlack};
padding: 10px;
top: 5px;
right: 5px;
border-radius: 8px;
`;
export const LogoCoverIcon = styled.img`
width: 38px;
    height: 24px;
`;