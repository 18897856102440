import { useCallback, useEffect, useState } from "react";
import {
  BackButton,
  GridContainer,
  ImageContainer,
  ImageIcon,
  SliderContainer,
  Wrapper,
} from "./CardsStyles";
import { ContentBox } from "../../components/ContentBox/ContentBox";
import { useThemeContext } from "../../theme";
import { Text } from "../../components/Text/Text";
import { Line } from "../Layout/SideBar/SideBarStyles";
import useWindowWidth from "../../hooks/useWindowWidth";
import { Btn } from "../../components/Button/Btn";
import CardSlider from "./SliderCard/SliderCard";
import { cardsSelector } from "../../logic/cards/CardsSelectors";
import { useDispatch, useSelector } from "react-redux";
import { CardsActions } from "../../logic/cards/CardsRedux";
import { KeyValueObj } from "../../types/types";
import Inputs from "./Inputs/Inputs";
import Kyc from "./Kyc/Kyc";
import useSmoothScrollToTop from "../../hooks/useSmoothScrollToTop";
import { highlightNumbers } from "./helpers/colorCost";
import { SkeletonGridImage, SkeletonGridItem } from "./SkeletonLoaders";
import { useLocation } from "react-router-dom";

const AVAILABLE_CARDS = [
  {
    name: "Visa",
    network: "Visa",
    featuresKey: "visa_virtual",
    imageKey: "visa_virtual_card",
  } as any,
  {
    name: "Mastercard",
    network: "Master",
    featuresKey: "master_virtual",
    imageKey: "master_virtual_card",
  } as any,

  {
    name: "Visa",
    network: "Visa",
    featuresKey: "visa_physical",
    imageKey: "visa_physical_card",
  } as any,
];

const CardsPage = () => {
  const location = useLocation();

  const [selectedCard, setSelectedCard] = useState(0);
  const [step, setStep] = useState(location.state?.step || 0);
  const [cardSetting, setCardSetting] = useState({
    type: "",
    network: "",
  });
  const [imageLoading, setImageLoading] = useState(true);
  const { theme } = useThemeContext();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();

  const {
    cardFeatures: { data: featuresData, fetching: fetchingFeatures },
  } = useSelector(cardsSelector);
  useEffect(() => {
    dispatch(CardsActions.cardFeatures.request());
  }, []);
  const handleImageLoad = useCallback(() => {
    setImageLoading(false);
  }, []);

  const cardNames: string[] = [
    "Virtual Card Visa",
    "Virtual Card Mastercard",
    "Physical Card Visa",
  ];
  const settingCard = useCallback(() => {
    switch (selectedCard) {
      case 0:
        setCardSetting({
          type: "Virtual",
          network: "Visa",
        });
        break;
      case 1:
        setCardSetting({
          type: "Virtual",
          network: "Mastercard",
        });
        break;
      case 2:
        setCardSetting({
          type: "Physical",
          network: "Visa",
        });
        break;
      default:
        break;
    }
  }, [selectedCard]);

  //Скролл вверх при изменении *step*
  useSmoothScrollToTop({ dep: step });

  return (
    <Wrapper>
      {/* основной контент */}

      <ContentBox
        padding={windowWidth > 800 ? "40px" : "16px 13px"}
        backColor={theme.colors.subBackground}
        direction="column"
        margin="24px 0 0 0"
      >
        {step !== 0 && (
          <BackButton
            src="/images/support/back_arrow.svg"
            onClick={() => setStep((prev: number) => prev - 1)}
          />
        )}

        {/*Screen with Slider */}
        {step === 0 && (
          <>
            <SliderContainer>
              <ContentBox
                backColor="none"
                direction={windowWidth > 1045 ? "row" : "column"}
                rowGap="16px"
              >
                <ContentBox
                  backColor="none"
                  width={windowWidth > 700 ? "500px" : "300px"}
                >
                  <CardSlider
                    setCard={setSelectedCard}
                    card={selectedCard}
                    data={featuresData?.cards}
                    cardNames={cardNames}
                  />
                </ContentBox>
              </ContentBox>

              <Btn
                label="Continue"
                buttonActive={selectedCard !== null}
                type="verification"
                func={() => {
                  setStep(1);
                  settingCard();
                }}
              />
            </SliderContainer>

            <Line style={{ marginTop: "40px", width: "100%" }} />
            <GridContainer>
              {/*@ts-ignore*/}
              {featuresData?.cards[
                AVAILABLE_CARDS[selectedCard].featuresKey
              ] ? (
                //@ts-ignore
                featuresData?.cards[
                  AVAILABLE_CARDS[selectedCard].featuresKey
                ].map((el: KeyValueObj, index: any) => (
                  <ContentBox justyfiContent="flex-start" key={index}>
                    <ImageContainer>
                      {imageLoading && <SkeletonGridImage />}
                      <ImageIcon
                        src={"/images/cards/earth.svg"}
                        style={{ display: imageLoading ? "none" : "block" }}
                        onLoad={handleImageLoad}
                        alt="'asd"
                      />
                    </ImageContainer>
                    <ContentBox
                      direction="column"
                      margin="0 0 0 15px"
                      alignItems="flex-start"
                    >
                      <Text
                        color={theme.colors.white}
                        size="26px"
                        fontStyle={theme.fonts.f600}
                        margin="0 auto 0 0"
                      >
                        {el.Key}
                      </Text>
                      <Text
                        color={theme.colors.textDescription}
                        size="18px"
                        fontStyle={theme.fonts.f400}
                        textAlign="left"
                      >
                        {highlightNumbers(el.Value)}
                      </Text>
                    </ContentBox>
                  </ContentBox>
                ))
              ) : (
                <>
                  <SkeletonGridItem desktop={windowWidth > 700} />
                  <SkeletonGridItem desktop={windowWidth > 700} />
                  <SkeletonGridItem desktop={windowWidth > 700} />
                  <SkeletonGridItem desktop={windowWidth > 700} />
                </>
              )}
            </GridContainer>
          </>
        )}
        {/*Screen with fields */}
        {step === 1 && (
          <>
            <Inputs
              cardName={cardNames[selectedCard]}
              setStep={setStep}
              cardSetting={cardSetting}
              step={step}
            />
          </>
        )}
        {/* KycScreen */}
        {step === 2 && <Kyc setStep={setStep} />}

        <Line style={{ marginTop: "40px", width: "100%" }} />
      </ContentBox>
      {/* нижние табы */}
      <ContentBox
        backColor="none"
        alignItems="flex-start"
        margin="50px 0 0 0"
        columnGap="24px"
        direction={windowWidth > 550 ? "row" : "column"}
        rowGap={windowWidth > 550 ? "0" : "24px"}
      >
        {/* {data[3].map((el, index) => (
          <Text
            maxWidth={windowWidth > 550 ? "50%" : "100%"}
            color={theme.colors.textDescription}
            key={index}
          >
            {el}
          </Text>
        ))} */}
      </ContentBox>
    </Wrapper>
  );
};

export default CardsPage;
