import React, { FC, useState } from "react";
import { Text } from "../../../components/Text/Text";
import { ContentBox } from "../../../components/ContentBox/ContentBox";
import { text } from "../../../theme/text";
import {
  FromButton,
  InputButton,
  InputWrapper,
  OptionItem,
  Options,
  SelectButton,
} from "./MethodOptianalStyles";
import { useThemeContext } from "../../../theme";
import useWindowWidth from "../../../hooks/useWindowWidth";
import i18n from "../../../i18n/i18n";

export type Props = {
  label: string;
  placeholder?: string;
  activeTab?: number;
  activeBox?: number;
  coinName?: string;
  setCoinName?: React.Dispatch<React.SetStateAction<string>>;
};
const { t } = i18n;
export const Input: FC<Props> = ({ label, placeholder }) => {
  const { theme } = useThemeContext();
  return (
    <>
      <Text
        color={theme.colors.white}
        size="20px"
        fontStyle={theme.fonts.f600}
        margin="0 0 5px 0"
      >
        {label}
      </Text>
      <ContentBox padding="16px">
        <InputWrapper placeholder={placeholder} />
        <InputButton>
          <img src="/images/Select_arrow_Down.png" alt="asd" />
        </InputButton>
      </ContentBox>
    </>
  );
};
export const Button: FC<Props> = ({ label }) => {
  const { theme } = useThemeContext();
  const windowWidth = useWindowWidth();
  return (
    <>
      <Text
        color={theme.colors.white}
        size="20px"
        fontStyle={theme.fonts.f600}
        margin="0 0 5px 0"
      >
        {label}
      </Text>
      <ContentBox padding={windowWidth > 900 ? "16px" : "8px"}>
        <FromButton>
          <img
            src="/images/add_yellow.png"
            alt="asd"
            style={{ marginRight: "5px" }}
          />
          {t("payments.addBankCard")}
        </FromButton>
      </ContentBox>
    </>
  );
};

export type ValueType = string | number;

export type DropdownItem = {
  title: string;
  value: ValueType;
  id: string | number;
};

export type DropdownData = DropdownItem[];

export type SelectInputProps = {
  label: string;
  // Массив элементов среди которых осуществляется выбор
  data: DropdownData;
  // Выбранный элемент
  value: DropdownItem;
  placeholder?: string;
  activeTab?: number;
  activeBox?: number;
  editable?: boolean;
  rightText?: string;
  // Если нужно пробросить верстку
  renderItem: (
    item: any,
    onPress: () => void,
    top?: boolean,
    opened?: boolean
  ) => React.ReactNode;
  // Колбэк, когда юзер выбрал элемент
  onValueChange: (v: DropdownItem) => void;
};

export const SelectInput: FC<SelectInputProps> = ({
  label,
  data,
  value,
  editable,
  rightText,
  renderItem,
  onValueChange,
}) => {
  const { theme } = useThemeContext();
  const windowWidth = useWindowWidth();

  const [opened, setOpened] = useState(false);

  return (
    <>
      <ContentBox
        justyfiContent="space-between"
        color={theme.colors.white}
        backColor="none"
        margin="0 0 5px 0"
      >
        <Text
          color={theme.colors.white}
          size="20px"
          fontStyle={theme.fonts.f600}
        >
          {label}
        </Text>
        {rightText && (
          <Text
            color={text.colors.gray}
            size="20px"
            fontStyle={theme.fonts.f600}
          >
            {rightText}
          </Text>
        )}
      </ContentBox>
      <ContentBox
        padding={windowWidth < 700 ? "12px 10px" : "15px"}
        border="1px solid #323232;"
      >
        {renderItem(value, () => {})}

        {editable && (
          <SelectButton
            openSelect={opened}
            onClick={() => editable && setOpened(!opened)}
          >
            <img src="/images/Select_arrow_Down.png" alt="asd" />
          </SelectButton>
        )}

        {opened && (
          <Options>
            {data.map((account) => (
              <OptionItem
                key={account.id}
                onClick={() => {
                  onValueChange(account);
                  setOpened(false);
                }}
              >
                {renderItem(account, () => {})}
              </OptionItem>
            ))}
          </Options>
        )}
      </ContentBox>
    </>
  );
};

SelectInput.defaultProps = {
  editable: true,
};

export const Simple: FC<Props> = ({ label }) => {
  const { theme } = useThemeContext();
  return (
    <>
      <Text
        color={theme.colors.white}
        size="20px"
        fontStyle={theme.fonts.f600}
        margin="0 0 5px 0"
      >
        {label}
      </Text>
      <ContentBox
        padding="15px"
        border="1px solid #323232;"
        justyfiContent="flex-start"
      >
        <ContentBox>
          <ContentBox padding="9px" backColor="#4F5853">
            <img
              src="/images/advacash.png"
              alt="asd"
              style={{ width: "35px", height: "29px" }}
            />
          </ContentBox>

          <ContentBox direction="column" margin="0 auto 0 10px">
            <Text
              color={theme.colors.white}
              size="20px"
              fontStyle={theme.fonts.f700}
              margin="0 auto 0 0"
            >
              {t("payments.advacashAcc")}
            </Text>
            <Text color={text.colors.gray} size="18px">
              {t("payments.loginOnStep")}
            </Text>
          </ContentBox>
        </ContentBox>
      </ContentBox>
    </>
  );
};
