import { ActionReducerMapBuilder, createAction, createReducer } from '@reduxjs/toolkit';
import { addAllRestReducers, addRestReducers, createRestActions, getDefaultRestState } from '../../store/restHelper';
import { NodeRestStateType } from '../../store/restHelper.d';
import { GetStorieByIdRestActions, GetStorieListRestActions } from './StorieActions';
import fp from 'lodash/fp';

export type StorieItem = {
  video?: string;
  image?: string;
  duration: number;
  position: number;
}

export type Storie = {
  id: string;
  apperDate: number;
  title: string;
  category: string;
  description?: string;
  visible: boolean;
  items: StorieItem[];
}

export type StorieWithIndex = Storie & {index: number};

type GetStorieByIdPayload = {
  storieId: string;
}

type GetStorieListPayload = {
  index: number;
}

const getStorieByIdRestActions = createRestActions<
  Storie,
  GetStorieByIdPayload
>(GetStorieByIdRestActions);

const getStorieListRestActions = createRestActions<
  Storie[],
  GetStorieListPayload
>(GetStorieListRestActions);

const StorieRestActions = {
  getStorieById: getStorieByIdRestActions,
  getStorieList: getStorieListRestActions,
}

const StorieActions = {
  ...StorieRestActions,
  setOpenedStorie: createAction<StorieWithIndex | undefined>("storie/setOpenedStorie"),
  setStoriesModalVisible: createAction<boolean>("storie/setStoriesModalVisible")
};

type StorieRestNodes = keyof typeof StorieRestActions;
type StorieStore = {
  openedStorie: StorieWithIndex | undefined;
  storiesModalVisible: boolean;
};



const initialStorieStore: StorieStore = {
  openedStorie: undefined,
  storiesModalVisible: false
}

const initialRestState = {
  getStorieById: getDefaultRestState(),
  getStorieList: getDefaultRestState<Storie[]>()
};

type StorieState = NodeRestStateType<
  StorieRestNodes, 
  StorieStore & typeof initialRestState
>;

type Builder = ActionReducerMapBuilder<StorieState>;

const storieReducer = createReducer(
  {...initialStorieStore, ...initialRestState}, 
  builder =>
    (fp.flow(addAllRestReducers<typeof StorieRestActions>(StorieRestActions))(builder) as Builder)
      .addCase(StorieActions.setOpenedStorie, (state, action) => {
        state.openedStorie = action.payload;
      })
      .addCase(StorieActions.setStoriesModalVisible, (state, action) => {
        state.storiesModalVisible = action.payload;
      })
);

export { storieReducer, StorieActions };