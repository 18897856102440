import React, { useState, useRef } from 'react';
import { useStyles } from './InternalFiatTransferStyles';
import { Box } from '@material-ui/core';
import cn from 'classnames';
import { WalletItem } from '../../../../../components/WalletItem';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { TextInputUnderlined } from '../../../../../components/TextInputUnderlined';
import { ShowDetails, ShowDetailsMethods } from '../../../../../components/ShowDetails';
import { StringValueItem } from '../../../../../components/StringValueItem';
import { CustomButton } from '../../../../../components/CustomButton';
import { cryptoSelector } from '../../../../../logic/crypto/CryptoSelectors';
import { CONFIG } from '../../../../../config';
import { useVerified } from '../../../../../hooks/useVerified';
import { VerificationRequired } from '../../../../../components/VerificationRequired';
import { prettyStringToNumber } from '../../../../../helpers/formatHelpers';
import { useHideErrors } from '../../../../../hooks/useHideErrors';
import { useAmountInput } from '../../../../../hooks/useAmountInput';
import { validate2FACode, validateFiatDepositAddress, validateFloat } from '../../../../../helpers/validationHelper';
import { CryptoActions } from '../../../../../logic/crypto/CryptoRedux';
import { userSelector } from '../../../../../logic/user/UserSelectors';
import { useDependentValue } from '../../../../../helpers/customHooks';
import currency from 'currency.js';
import { getAdaptivePriceFormatter } from '../../../../../helpers/price';

export type InternalFiatTransferProps = {};

const InternalFiatTransfer: React.FC<InternalFiatTransferProps> = ({}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const dispatch = useDispatch();

  const {
    enabled2FA,
  } = useSelector(userSelector);

  const {
    paymentsPageSelectedAccount: accountData,
    transferCheckOnlyResult,
    transferCheckOnlyFetching,
    transfer: {
      fetching: fetchingTransfer
    },
  } = useSelector(cryptoSelector);

  const showDetailsRef = useRef<ShowDetailsMethods>(null);
  const [checkOnlyMode, setCheckOnlyMode] = useState(true);
  const [toDepositAddress, setToDepositAddress] = useState("");
  const [code2FA, setCode2FA] = useState("");

  const userFullyVerified = useVerified();

  const {
    hideErrors,
    setHideErrors,
    onInputErrorAnimationEnd
  } = useHideErrors();

  const {
    amount,
    setAmount,
    handleAmountInput
  } = useAmountInput();

  const operationFeeText = useDependentValue(() => {
    if (!accountData)
      return "-";
    if (checkOnlyMode)
      return t("pressPreview");

    return `${transferCheckOnlyResult?.from_commission ? currency(Number(transferCheckOnlyResult.from_commission), getAdaptivePriceFormatter(Number(transferCheckOnlyResult.from_commission), "")).format() : "-"} ${accountData.currency_code}`;
  }, [checkOnlyMode, t, transferCheckOnlyResult, accountData]);

  const receiverNameText = useDependentValue(() => {
    if (checkOnlyMode)
      return t("pressPreview");

    return transferCheckOnlyResult?.to_name ?? "-";
  }, [checkOnlyMode, t, transferCheckOnlyResult]);

  const transferRequest = (check_only: boolean = false) => {
    dispatch(CryptoActions.transfer.request({
      from_account_id: accountData!.id,
      to_account_id: 0,
      deposit_address: toDepositAddress,
      to_blockchain: "none",
      amount: Number(amount),
      otp_code: check_only ? undefined : code2FA,
      check_only,
      normal_sending: false,
      successCallback: check_only ? () => {
        setCheckOnlyMode(false);
        showDetailsRef?.current?.open();
      } : () => {
        setCheckOnlyMode(true);
        setAmount("");
        setToDepositAddress("");
        setCode2FA("");
        showDetailsRef?.current?.close();
      },
      showErrorToastOnCheck: true
    }));
  }

  const handlePressSend = () => {
    if (validateFloat(amount) && validateFiatDepositAddress(toDepositAddress) && (!enabled2FA || checkOnlyMode || validate2FACode(code2FA))) {
      transferRequest(checkOnlyMode);
    } else {
      setHideErrors(false);
    }
  }

  if (!userFullyVerified)
    return <VerificationRequired />;

  if (!accountData)
    return null;

  return (
    <Box className={cn(s.globalContainer)}>
      <Box className={cn(s.container)}>
        <Box className={cn(s.spaceBetween)}>
          <Box className={cn(s.topBlock)}>

            <p className={cn(s.whiteTextSemiBold)}>
              {t('from')}
            </p>
            
            <WalletItem 
              image={`${CONFIG.ZED_BASE_URL}/${accountData.currency_flag}`}
              userHasThisWallet={true}
              // userCurrencySymbol={getCurrencySymbol(userCurrency)}
              userCurrencySymbol="$"
              balance={accountData.balance * prettyStringToNumber(accountData.currency_price)}
              cryptoBalance={accountData.balance}
              name={accountData.currency_code}
              walletName={accountData.name}
              marginVertical={theme.metrics.x3}
            />
            
            <Box className={cn(s.inputsContainer)}>
              <TextInputUnderlined
                value={amount} 
                placeholder={t("amount") as string}
                onChangeText={(text) => handleAmountInput(text, setAmount)} 
                validationOk={hideErrors || validateFloat(amount)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />

              <TextInputUnderlined
                value={toDepositAddress} 
                placeholder={t("internalFiatTransferScreen.toDepositAddress") as string}
                onChangeText={(text) => setToDepositAddress(text)} 
                validationOk={hideErrors || validateFiatDepositAddress(toDepositAddress)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />

              <TextInputUnderlined
                value={code2FA} 
                placeholder={t("twoFACode") as string}
                onChangeText={(text) => setCode2FA(text)} 
                validationOk={hideErrors || validate2FACode(code2FA)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />

              <ShowDetails 
                ref={showDetailsRef}
                marginBottom={0}
              >
                <StringValueItem 
                  label={t("operationFee")}
                  value={operationFeeText}
                />
                <StringValueItem 
                  label={t("receiverName")}
                  value={receiverNameText}
                />
              </ShowDetails>
            </Box>
          </Box>

          <CustomButton
            title={checkOnlyMode ? t("buttons.preview") as string : t("buttons.send") as string}
            bgColorActive={theme.colors.yellowMain}
            colorActive={theme.colors.black}
            defaultSizing
            onPress={handlePressSend}
            spinner={checkOnlyMode ? transferCheckOnlyFetching : fetchingTransfer}
          />
        </Box>
      </Box>
    </Box>
  );
};

InternalFiatTransfer.defaultProps={}

export { InternalFiatTransfer };