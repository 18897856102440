import { ApisauceInstance } from "apisauce";

export const addAxiosResponseInterceptor = (sauce: ApisauceInstance) => {
  sauce.axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const response = error.response;
      const originalRequest = error.config;

      // Redirect to zed-pay API
      if (
        originalRequest &&
        response?.status === 307 &&
        response?.headers &&
        response.headers["x-location"]
      ) {
        // console.log("originalRequest", originalRequest);

        originalRequest.headers["Authorization"] =
          response.headers["x-auth-token"] ?? "";
        originalRequest.baseURL = response.headers["x-location"];
        originalRequest.url = "";

        return sauce.axiosInstance(originalRequest);
      }

      return Promise.reject(error);
    }
  );
};
