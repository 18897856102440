import React, { FC, useState } from "react";
import { InfoContainer, Wrapper, useStyles } from "./HistoryItemStyles";
import { Text } from "../../Text/Text";
import { text } from "../../../theme/text";
import { useThemeContext } from "../../../theme";
import { secureAddress } from "../../../helpers/numbers";
import { getAdaptivePriceFormatter } from "../../../helpers/price";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import currency from "currency.js";
import cn from "classnames";

type Props = {
  title?: string;
  address?: string;
  amount: number;
  currencyCode: string;
  transaction: string;
  fontSize?: number;
  maxTitleLen?: number;
  imgSize?: number;
  expandable?: boolean;
  transferId?: number;
};

const HistoryItem: FC<Props> = ({
  title,
  address,
  amount,
  currencyCode,
  transaction,
  fontSize,
  maxTitleLen,
  imgSize,
  expandable,
  transferId
}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();
  
  const [opened, setOpened] = useState(false);

  const colorAmount = amount < 0 ? text.colors.red : text.colors.lightGreen;
  const img = +amount < 0 ? "/images/send_From.png" : "/images/send_To.png";
  const addressRes = address
    ? secureAddress(address)
    : // @ts-ignore
      // prettier-ignore
      `${title.length > maxTitleLen? title.slice(0, maxTitleLen) + '...': title}`;

  const { theme } = useThemeContext();
  return (
    <Wrapper onClick={() => expandable && setOpened((v) => !v)} style={{cursor: expandable ? "pointer" : undefined}}>
      <Box className={s.row}>
        <img src={img} style={{ width: imgSize, height: imgSize }} />
        <InfoContainer>
          <Text
            color={theme.colors.white}
            size={`${fontSize}px`}
            fontStyle={theme.fonts.f700}
          >
            {addressRes}
          </Text>
          <Text color={text.colors.lightGray} size="18px">
            {transaction}
          </Text>
        </InfoContainer>
        <Text
          color={colorAmount}
          size={`${fontSize}px`}
          fontStyle={theme.fonts.f600}
        >
          {amount > 0 && "+"}
          {currency(amount, getAdaptivePriceFormatter(amount, "")).format()}
          {` ${currencyCode}`}
        </Text>
      </Box>

      {(opened && transferId) &&
        <Box className={cn(s.externalDataContainer)}>
          <Box className={cn(s.infoRow)}>
            <p className={cn(s.greySubTextSmallSemiBold)}>
              {`${t("transferId")}:`}
            </p>
            <p className={cn(s.yellowTextSmallSemiBold)}>
              {transferId}
            </p>
          </Box>
        </Box>
      }
    </Wrapper>
  );
};

HistoryItem.defaultProps = {
  fontSize: 22,
  maxTitleLen: 20,
  imgSize: 50,
  expandable: false
};

export default HistoryItem;