import React, { memo, useState, useEffect, useCallback } from 'react';
import { useStyles } from './TextInputUnderlinedStyles';
import { Box } from '@material-ui/core';
import { theme } from '../../theme/default/theme';
import { DataPickerItemType } from '../../types/types';
import { stringWithMaxLen } from '../../helpers/strings';
import { NoData } from '../NoData';
import { useTranslation } from 'react-i18next';
import InputMask from "react-input-mask";
import classNames from 'classnames';
import CloseIcon from './../TextInput/assets/close';
import 'animate.css';

export type TextInputUnderlinedProps = {
  value: string;
  validationOk?: undefined | boolean;
  onErrorAnimationEnd?: () => void;
  placeholder?: string;
  onChangeText: (t: string) => void;
  bgColor?: string;
  color?: string;
  paddingLeft?: number;
  paddingRight?: number;
  borderActive?: string;
  borderUnactive?: string;
  borderError?: string;
  textAlign?: string;
  fontSize?: number;
  height?: number;
  maxLength?: number;
  editable?: boolean;
  label?: string;
  labelColor?: string;
  rightLabel?: string;
  shouldHideRightLabel?: boolean;
  rightLabelFontSize?: number;
  secure?: boolean;
  inputType?: string;
  marginTop?: number;
  justifyContent?: "center" | "flex-start" | "flex-end";
  mask?: string | (string | RegExp)[];
  picker?: boolean;
  pickerValue?: string | number | null | undefined;
  items?: DataPickerItemType[];
  loadingPickerItems?: boolean;
  onClear?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onPickerValueChange?: (value: DataPickerItemType) => void;
};

const TextInputUnderlined: React.FC<TextInputUnderlinedProps> = ({
  value,
  validationOk,
  onErrorAnimationEnd,
  placeholder,
  onChangeText,
  bgColor,
  color,
  paddingLeft,
  paddingRight,
  borderActive,
  borderError,
  borderUnactive,
  textAlign,
  fontSize,
  height,
  maxLength,
  editable,
  label,
  labelColor,
  rightLabel,
  shouldHideRightLabel,
  rightLabelFontSize,
  secure,
  marginTop,
  justifyContent,
  inputType,
  mask,
  picker,
  pickerValue,
  items,
  loadingPickerItems,
  onClear,
  onBlur,
  onFocus,
  onPickerValueChange
}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);
  const [secureEntry, setSecureEntry] = useState(true);
  const [pickerOpened, setPickerOpened] = useState(false);

  const onValueChangeInner = useCallback((item: DataPickerItemType) => {
    onPickerValueChange!(item);
    setPickerOpened(false);
  }, []);

  useEffect(() => {
    if (validationOk === false && onErrorAnimationEnd)
      setTimeout(() => {
        onErrorAnimationEnd();
      }, 1000);
  }, [validationOk]);

  return (
    <Box className={classNames(s.globalContainer)} style={{marginTop}}>
      {label &&
        <p style={{margin: 0, color: labelColor, fontFamily: theme.fonts.f600.fontFamily, marginBottom: theme.metrics.x2}}>
          {label}
        </p>
      }
      {secure &&
        <Box className={classNames(s.rightIconContainer)} onClick={() => setSecureEntry((v) => !v)}>
          <img src='/images/eye.png'/>
        </Box>
      }
      <Box 
        className={classNames(s.container, "animate__animated", {"animate__pulse": validationOk === false})} 
        style={{
          height,
          backgroundColor: bgColor, 
          paddingLeft, 
          paddingRight: secure === false? paddingRight: theme.metrics.x7 * 2,
          borderBottom: validationOk === false ? borderError : value.length > 0 ? borderActive : borderUnactive,
          justifyContent
        }}
      >
        {mask?
          <InputMask 
            mask={mask}
            className={classNames(s.input)}
            style={{ width: '100%', color, fontSize }}
            placeholder={placeholder}
            onChange={(event) => onChangeText(event.target.value)} 
            value={value} 
          />
        :
          <input 
            onClick={(e) => e.stopPropagation()}
            spellCheck={false}
            className={classNames(s.input)}
            size={1}
            // @ts-ignore
            style={{ color, textAlign, fontSize, width: rightLabel ? "100%" : '100%' }}
            type={inputType ?? (secure && secureEntry ? 'password' : 'text')}
            value={value}
            placeholder={placeholder}
            onChange={(event) => onChangeText(event.target.value)}
            onFocus={() => {
              setFocused(true);
              onFocus && onFocus();
              picker && setPickerOpened(true);
            }}
            onBlur={() => {
              setFocused(false);
              onBlur && onBlur();
              picker && setTimeout(() => {
                setPickerOpened(false);
              }, 200);
            }}
            maxLength={maxLength}
            disabled={!editable}
          />
        }
        {onClear &&
          <Box className={classNames(s.clearBtn)} onClick={onClear}>
            <CloseIcon fill={color}/>
          </Box>
        }
        {(rightLabel && !shouldHideRightLabel) &&
          <p className={classNames(s.rightLabel)} style={{fontSize: rightLabelFontSize}}>
            {rightLabel}
          </p>
        }
      </Box>
      
      {(picker && items && onPickerValueChange && pickerOpened) && (
        <Box 
          className={classNames(s.pickerContainer)}
          style={{top: height}}
        >
          {!items.length || loadingPickerItems ?
            <NoData text={t("nothingFound") as string} textColor={theme.colors.greySubTextDesc} fontSize={16} loading={loadingPickerItems}/>
          :
            items.map((item) => (
              <Box
                key={item.value}
                className={classNames(s.pickerItem, {[s.pickerItemSelected]: item.value === pickerValue})}
                onClick={() => onValueChangeInner(item)}
              >
                <p className={classNames(s.pickerItemText)}>
                  {stringWithMaxLen(item.label, 40)}
                </p>
              </Box>
            ))
          }
        </Box>
      )}
    </Box>
  );
};

TextInputUnderlined.defaultProps={
  validationOk: undefined,
  bgColor: theme.colors.transparent,
  color: 'white',
  paddingLeft: 0,
  paddingRight: 0,
  placeholder: '',
  borderActive: `1px solid ${theme.colors.yellowMain}`,
  borderUnactive: '1px solid #323232',
  borderError: 'solid 1px #f00',
  textAlign: 'left',
  fontSize: 16,
  rightLabelFontSize: 16,
  height: 50,
  maxLength: undefined,
  editable: true,
  labelColor: "white",
  secure: false,
  marginTop: 0,
  justifyContent: 'flex-start',
  picker: false,
  loadingPickerItems: false
}

const MemorizedComponent = memo(TextInputUnderlined);
export { MemorizedComponent as TextInputUnderlined };