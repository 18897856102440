import React, { memo, useState, useMemo } from 'react';
import { useStyles } from './BlockchainWithdrawStyles';
import { Box } from '@material-ui/core';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../../../../components/CustomButton';
import { useThemeContext } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { cryptoSelector } from '../../../../../logic/crypto/CryptoSelectors';
import { useAmountInput } from '../../../../../hooks/useAmountInput';
import { useHideErrors } from '../../../../../hooks/useHideErrors';
import { useDependentState, useDependentValue } from '../../../../../helpers/customHooks';
import { validate2FACode, validateBtcAddress, validateEthAddress, validateFloat } from '../../../../../helpers/validationHelper';
import { BLOCKCHAIN_LABEL_TABLE, BLOCKCHAIN_NAME_TABLE, BLOCKCHAIN_TYPE_TABLE } from '../../../../../data/zedpay';
import { WalletItem } from '../../../../../components/WalletItem';
import { CONFIG } from '../../../../../config';
import { prettyStringToNumber } from '../../../../../helpers/formatHelpers';
import { SettingsItem } from '../../../../../components/SettingsItem';
import { BlockchainType, DataPickerItemType } from '../../../../../types/types';
import { TextInputUnderlined } from '../../../../../components/TextInputUnderlined';
import { CryptoActions } from '../../../../../logic/crypto/CryptoRedux';
import { ShowDetails } from '../../../../../components/ShowDetails';
import { StringValueItem } from '../../../../../components/StringValueItem';

export type BlockchainWithdrawProps = {};

const BlockchainWithdraw: React.FC<BlockchainWithdrawProps> = ({}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const dispatch = useDispatch();

  const {
    transfer: {
      fetching: fetchingTransfer
    },
    paymentsPageSelectedAccount: accountData
  } = useSelector(cryptoSelector);

  const [depositAddress, setDepositAddress] = useState("");
  const [code2FA, setCode2FA] = useState("");

  const {
    amount,
    setAmount,
    handleAmountInput
  } = useAmountInput();

  const {
    hideErrors,
    setHideErrors,
    onInputErrorAnimationEnd
  } = useHideErrors();

  const blockchainDropdownData = useDependentValue<DataPickerItemType[]>(() => {
    if (!accountData)
      return [] as DataPickerItemType[];

    return accountData.address.map((address) => ({
      ...address, 
      label: BLOCKCHAIN_NAME_TABLE[address.blockchain], 
      value: BLOCKCHAIN_TYPE_TABLE[address.blockchain]
    })) as DataPickerItemType[];
  }, [accountData]);

  const [toBlockchain, setToBlockchain] = useDependentState<any>(() => {
    return blockchainDropdownData[0] ? blockchainDropdownData[0].value : "ethereum";
  }, [blockchainDropdownData]);

  const validateDepositAddress = useMemo(() => {
    return accountData?.currency_code === "BTC" ? validateBtcAddress : validateEthAddress;
  }, [accountData]);

  const handleSend = () => {
    if (validateDepositAddress(depositAddress) && validate2FACode(code2FA) && validateFloat(amount)) {
      dispatch(CryptoActions.transfer.request({
        // @ts-ignore
        from_account_id: accountData.id,
        to_account_id: 0,
        deposit_address: depositAddress,
        to_blockchain: toBlockchain as BlockchainType,
        amount: Number(amount),
        otp_code: code2FA,
        check_only: false,
        normal_sending: false,
        successCallback: () => {
          // setToBlockchain();
          setDepositAddress("");
          setAmount("");
          setCode2FA("");
        }
      }));
    } else {
      setHideErrors(false);
    }
  }

  if (!accountData)
    return null;

  return (
    <Box className={cn(s.globalContainer)}>
      <Box className={cn(s.container)}>
        <Box className={cn(s.spaceBetween)}>
          <Box className={cn(s.topBlock)}>

            <p className={cn(s.whiteTextSemiBold)}>
              {t('from')}
            </p>
            
            <WalletItem 
              image={`${CONFIG.ZED_BASE_URL}/${accountData.currency_flag}`}
              userHasThisWallet={true}
              // userCurrencySymbol={getCurrencySymbol(userCurrency)}
              userCurrencySymbol="$"
              balance={accountData.balance * prettyStringToNumber(accountData.currency_price)}
              cryptoBalance={accountData.balance}
              name={accountData.currency_code}
              walletName={accountData.name}
              marginVertical={theme.metrics.x3}
            />

            <Box className={cn(s.dataPicker)}>
              <SettingsItem
                label={`${t("blockchain")}:`}
                labelColor={theme.colors.greySubText}
                rightLabel={BLOCKCHAIN_LABEL_TABLE[toBlockchain as any]}
                onValueChange={(v) => setToBlockchain(v)}
                picker={true}
                items={blockchainDropdownData}
                value={toBlockchain}
                disabled={accountData.address.length <= 1}
              />
            </Box>
            
            <Box className={cn(s.inputsContainer)}>
              <TextInputUnderlined
                value={depositAddress}
                placeholder={t('walletAddress') as string}
                onChangeText={text => setDepositAddress(text)}
                validationOk={hideErrors || validateDepositAddress(depositAddress)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />

              <TextInputUnderlined
                value={amount} 
                placeholder={t("amount") as string}
                onChangeText={(text) => handleAmountInput(text, setAmount)} 
                validationOk={hideErrors || validateFloat(amount)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />

              <TextInputUnderlined
                value={code2FA} 
                placeholder={t("twoFACode") as string}
                onChangeText={(text) => setCode2FA(text)} 
                validationOk={hideErrors || validate2FACode(code2FA)}
                onErrorAnimationEnd={onInputErrorAnimationEnd}
              />

              <ShowDetails marginBottom={0}>
                <StringValueItem 
                  label={t("payments.blockchain")}
                  value={BLOCKCHAIN_LABEL_TABLE[toBlockchain as any]}
                />
                <StringValueItem 
                  label={t("payments.operationTime")}
                  value={t("defaultConfirmOperationTime")}
                />
              </ShowDetails>
            </Box>
          </Box>

          <CustomButton
            title={t("buttons.send") as string}
            bgColorActive={theme.colors.yellowMain}
            colorActive={theme.colors.black}
            defaultSizing
            onPress={handleSend}
            spinner={fetchingTransfer}
          />
        </Box>
      </Box>
    </Box>
  );
};

BlockchainWithdraw.defaultProps={}

const MemorizedComponent = memo(BlockchainWithdraw);
export { MemorizedComponent as BlockchainWithdraw };