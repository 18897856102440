import { Instruction, InstructionGroup } from "@appklaar/codebud";
import { GeneralActions } from "./../logic/general/GeneralRedux";

export const getGeneralInstructions = (store: any): (Instruction | InstructionGroup)[] => ([
  {
    groupId: "General",
    groupDescription: "General entity instructions",
    groupColor: "#007AFF",
    groupInstructions: [
      {
        id: "listCountries",
        description: "Get a list of countries.",
        handler: () => {
          store.dispatch(GeneralActions.listCountries.request());
        },
      },
      {
        id: "currencyListToOpen",
        description:
          "Get a list of currencies that a customer can open a wallet on.",
        parametersDescription: {
          customer_id: "number",
        },
        handler: (data) => {
          store.dispatch(GeneralActions.currencyListToOpen.request(data));
        },
      },
      {
        id: "generateQRCode",
        description: "Generate QR code from a string.",
        parametersDescription: {
          text: "string",
        },
        handler: (data) => {
          store.dispatch(GeneralActions.generateQRCode.request(data));
        },
      },
      {
        id: "getDocumentsTypes",
        description: "request GET document types",
        handler: () => {
          store.dispatch(GeneralActions.listDocuments.request());
        },
      },
      {
        id: "faq",
        description: "Get a list of FAQs.",
        parametersDescription: {
          items: "number",
        },
        handler: (data) => {
          store.dispatch(GeneralActions.faq.request(data));
        },
      },
      {
        id: "about",
        description: "Get contact info of the company.",
        handler: () => {
          store.dispatch(GeneralActions.about.request());
        },
      },
      {
        id: "searchBanks",
        description: "Get a list of banks from a specific country.",
        parametersDescription: {
          swift_country_id: "string",
          query: "string"
        },
        handler: (data) => {
          store.dispatch(GeneralActions.searchBanks.request(data));
        }
      },
      {
        id: "getLocalBanks",
        description: "Get a list if local banks. Filter is optional.",
        parametersDescription: {
          country_id: "string",
          currency_code: "string"
        },
        handler: (data) => {
          store.dispatch(GeneralActions.localBanks.request(data));
        }
      },
      {
        id: "commissions",
        description: "Get a list of service commissions.",
        parametersDescription: {
          view: "boolean"
        },
        handler: (data) => {
          store.dispatch(GeneralActions.commissions.request(data));
        }
      },
    ]
  },
]);