import React, {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
} from "react";
import {
  Button,
  CloseButton,
  Container,
  ContainerEmpty,
  ContentWrapper,
  HeaderContainer,
  OneDayContainer,
  Wrapper,
} from "./HistoryBarStyles";
import { Text } from "../Text/Text";
import { Line } from "../../Pages/Layout/SideBar/SideBarStyles";
import { text } from "../../theme/text";
import HistoryItem from "./HistoryItem";
import { useThemeContext } from "../../theme";
import i18n from "../../i18n/i18n";
import { cardsSelector } from "../../logic/cards/CardsSelectors";
import { useDispatch, useSelector } from "react-redux";
import { userIdSelector } from "../../logic/user/UserSelectors";
import { CardsActions } from "../../logic/cards/CardsRedux";

type Props = {
  setVisible: Dispatch<SetStateAction<boolean>>;
};

const HistoryBar: FC<Props> = ({ setVisible }) => {
  const { theme } = useThemeContext();
  const { t } = i18n;
  const dispatch = useDispatch();

  const userId = useSelector(userIdSelector);

  const {
    getTransactions: { fetching: fetchingTransactions, data: transactionsData },
  } = useSelector(cardsSelector);
  const onRefresh = useCallback(() => {
    dispatch(
      CardsActions.getTransactions.request({
        customer_id: userId as number,
      })
    );
  }, []);

  // ComponentDidMount
  useEffect(() => {
    onRefresh();
  }, []);
  return (
    <Wrapper onClick={() => setVisible(false)}>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        <HeaderContainer>
          <Text
            color={theme.colors.white}
            size="44px"
            fontStyle={theme.fonts.f600}
            margin="0 auto 0 24px"
          >
            {t("history.history")}
          </Text>
          <CloseButton onClick={() => setVisible(false)}>X</CloseButton>
        </HeaderContainer>

        <Line style={{ width: "100%" }} />
        {transactionsData && transactionsData.length > 0 ? (
          <Container>
            <OneDayContainer>
              {transactionsData.map((transaction) => (
                <>
                  <Text
                    color={text.colors.lightGray}
                    size="20px"
                    fontStyle={theme.fonts.f400}
                    margin="0 0 24px 0"
                  >
                    {transaction.date_time}
                  </Text>

                  <HistoryItem
                    amount={
                      transaction.debit > 0
                        ? transaction.debit
                        : -transaction.credit
                    }
                    currencyCode={transaction.currency_code}
                    title={transaction.status}
                    transaction={transaction.transaction_type}
                    expandable={true}
                    transferId={transaction.transfer_id}
                  />
                </>
              ))}
            </OneDayContainer>
          </Container>
        ) : (
          <ContainerEmpty>
            <img src="/images/History_Wallet.png" alt="asd" />
            <Text
              color={theme.colors.white}
              size="28px"
              fontStyle={theme.fonts.f700}
              textAlign="center"
              margin="48px 0 0 0"
            >
              {t("history.empty1")}
            </Text>
            <Text
              color={text.colors.lightGray}
              size="16px"
              fontStyle={theme.fonts.f400}
              margin="24px 0 0 0"
            >
              {t("history.empty2")}
            </Text>
            <Button>
              <Text color="black" size="20px" fontStyle={theme.fonts.f600}>
                {t("history.empty3")}
              </Text>
            </Button>
          </ContainerEmpty>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
export default memo(HistoryBar);
