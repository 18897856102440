import { CONFIG } from "../config";
import { CurrencyT, SwapReceiveTable } from '../types/types';

export enum Trand {
  Positive,
  Negative
};

const CURRENCY_SYMBOL_TABLE: {[c in CurrencyT]: string} = {
  usd: "$",
  eur: "€"
};

export const getCurrencySymbol = (currency: CurrencyT): string => (CURRENCY_SYMBOL_TABLE[currency]);

export const calculatePriceChangePercent = (prevPrice: number, price: number) => {
  const sign = prevPrice < price ? 1: -1;

  return sign * Math.round(Math.abs(prevPrice - price) / prevPrice * 1e4) / 1e2;
}

export const getAdaptivePriceFormatter = (price: number, symbol: string = "$") => {
  const afterDecimalStr = price.toString().split('.')[1] || '';

  if (price > 1 || afterDecimalStr.length <= 2)
    return {...CONFIG.CURRENCY_OPTIONS, symbol};

  const precision = Math.min(afterDecimalStr.length, 6);
  return {...CONFIG.CURRENCY_OPTIONS, symbol, precision};
}

export const calculateChange = (oldVal: number, newVal: number) => {
  return (newVal - oldVal) / oldVal;
}

export const exchangeCalculateEstimateBuy = ({sellValue, exchangeFromCoinId, exchangeToCoinId, swapReceiveTable}: {sellValue: string, exchangeFromCoinId: number, exchangeToCoinId: number, swapReceiveTable: SwapReceiveTable}) => {
  const rateFromTo = swapReceiveTable[exchangeFromCoinId].find((v) => v.id === exchangeToCoinId);
  if (rateFromTo) {
    const estimateBuy = rateFromTo.swap_rate * Number(sellValue);
    const afterDecimalStr = estimateBuy.toString().split('.')[1] || '';
    return estimateBuy.toFixed(Math.min(afterDecimalStr.length, 6)).toString();
  }

  return "0";
};