import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../../theme/default/theme';

export const useStyles = makeStyles({
  globalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'yellow'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    // backgroundColor: 'blue'
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
    // backgroundColor: 'red',
    justifyContent: 'space-between'
  },
  topBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  whiteTextSemiBold: {
    fontFamily: theme.fonts.f600.fontFamily,
    color: theme.colors.white,
    fontSize: 20,
    marginLeft: 0,
    marginTop: 0,
    marginRight: 0,
    marginBottom: theme.metrics.x4
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.metrics.x4,
    marginBottom: theme.metrics.x8
  },
  whiteText: {
    fontFamily: theme.fonts.f400.fontFamily,
    color: theme.colors.white,
    fontSize: 18
  },
  dataPicker: {
    display: 'flex',
    width: '100%',

  }
});