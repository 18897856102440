import { FC, useEffect } from "react";
import LoaderRing from "../../../helpers/Loader/LoaderRing";
import { useThemeContext } from "../../../theme";
import BlurWrapper from "../../BlurWrapper/BlurWrapper";
import { Text } from "../../Text/Text";
import { Button, ButtonsWrapper, Content } from "./AreYouSureStyles";

type Props = {
  setModal: any;
  func: () => void;
  fetch?: any;
  success?: any;
  setPage?: any;
  yes: { color: string; text: string };
  no: { color: string; text: string };
};

const AreYouSure: FC<Props> = ({
  setModal,
  func,
  fetch,
  success,
  setPage,
  yes,
  no,
}) => {
  useEffect(() => {
    if (success) {
      setModal(false);
      setPage("tickets");
    }
  }, [success]);
  const { theme } = useThemeContext();
  return (
    <BlurWrapper setModal={setModal}>
      <Content onClick={(e) => e.stopPropagation()}>
        <Text
          color={theme.colors.white}
          margin="20px auto 40px auto"
          size="26px"
          fontStyle={theme.fonts.f600}
        >
          Are you sure?
        </Text>
        <ButtonsWrapper>
          <Button
            onClick={() => func()}
            style={{
              backgroundColor: yes.color,
            }}
          >
            {!fetch ? (
              <Text color={theme.colors.white} fontStyle={theme.fonts.f600}>
                {yes.text}
              </Text>
            ) : (
              <LoaderRing size="12px" />
            )}
          </Button>
          <Button
            onClick={() => setModal(false)}
            style={{
              backgroundColor: no.color,
            }}
          >
            <Text color={theme.colors.white} fontStyle={theme.fonts.f600}>
              {no.text}
            </Text>
          </Button>
        </ButtonsWrapper>
      </Content>
    </BlurWrapper>
  );
};
export default AreYouSure;
