import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useThemeContext } from "./theme";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import Router from "./router";
import "react-toastify/dist/ReactToastify.css";
import HelmTags from "./helpers/Helmet/Helmet";
import {
  metaDescription,
  metaImage,
  metaTitle,
} from "./helpers/Helmet/HelmetTags";

function App() {
  // ComponentDidMount

  const { theme } = useThemeContext();

  const [location, setLocation] = useState(null);

  return (
    <>
      <HelmTags
        title={metaTitle(location ? location : "")}
        desc={metaDescription(location ? location : "")}
        img={metaImage}
      />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Router setLocation={setLocation} />
        </BrowserRouter>

        <ToastContainer
          position="top-center"
          autoClose={3500}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
        />
      </ThemeProvider>
    </>
  );
}

export default App;
