import React, { memo } from "react";
import { useStyles } from "./NewsItemStyles";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import cn from "classnames";

export type NewsItemProps = {
  subTitle: string;
  title: string;
  tag?: string;
  image: string;
  blurText: string;
  onPress?: () => void;
  ad?: boolean;
  gradient: string[];
};

const NewsItem: React.FC<NewsItemProps> = ({
  subTitle,
  title,
  tag,
  image,
  ad,
  blurText,
  onPress,
  gradient,
}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Box
      className={cn(s.container, { [s.marginVertical]: !!ad })}
      style={{
        background: `linear-gradient(0deg, ${gradient[0]} 0%, ${gradient[1]} 60%)`,
      }}
    >
      <img className={cn(s.image)} src={image} alt="news image" />
      <Box className={cn(s.topPart)}>
        <p className={cn(s.subTitle)}>{subTitle}</p>
        <p className={cn(s.title)}>{title}</p>
        {!!tag && (
          <Box className={cn(s.tagCont)}>
            <p className={cn(s.tag)}>{tag}</p>
          </Box>
        )}
      </Box>
      <Box className={cn(s.blurCont)}>
        <p className={cn(s.blurText)}>{blurText}</p>
        {onPress && (
          <Box onClick={onPress} className={cn(s.button)}>
            <p className={s?.buttonText}>{t("buttons.invest")}</p>
          </Box>
        )}
      </Box>
    </Box>
  );
};

NewsItem.defaultProps = {};

const MemorizedComponent = memo(NewsItem);
export { MemorizedComponent as NewsItem };
