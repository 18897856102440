import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
} from '@reduxjs/toolkit';
import {
  addAllRestReducers,
  addRestReducers,
  createRestActions,
  getDefaultRestState,
} from '../../store/restHelper';
import {NodeRestStateType} from '../../store/restHelper.d';
import {
  ListCountriesRestActions,
  ListDocumentsRestActions,
  CurrencyListToOpenRestActions,
  UploadFileRestActions,
  GenerateQRCodeRestActions,
  FaqRestActions,
  AboutRestActions,
  GetBankAccountsRestActions,
  SearchBanksRestActions,
  LocalBanksRestActions,
  SwiftCountriesRestActions,
  CommissionsRestActions,
  CurrencyListRestActions,
} from './GeneralActions';
import fp from 'lodash/fp';
import {Country, WithTimestamp, DataPickerItemType, Bank, LocalBank, SwiftCountry, CommissionsTable} from '../../types/types';

export type Currency = {
  id: number;
  name: string;
  code: string;
  currency_type: 'Fiat' | 'Crypto';
  color: string;
  country: string;
  flag: string;
  sell: number;
  buy: number;
  swift_commission: number;
  withdrawal_commission: number;
  swap_commission: number;
  referral_bonus: number;
  minimum_input: number;
  maximum_output: number;
  details: any[];
  status: string;
};

export type BankAccountInfo = {
  currency_code: string;
  account_holder_name: string;
  account_holder_country: string;
  account_holder_address: string;
  account_holder_email: string;
  swift_code: string;
  bank_name: string;
  bank_code: string;
  branch_code: string;
  account_number: string;
  bank_country: string;
  bank_city: string;
  bank_address: string;
  intermediary_bank_swift_code: string;
  intermediary_bank_country: string;
  remark: string;
};

export type QAPair = {
  question: string;
  answer: string;
}

export enum ContactType {
  InternetLink = 1,
  Phone = 2
}

export type Contact = {
  title: string;
  link: string;
  type: ContactType;
}

export type CurrencyTable = {[key: string]: Currency};

type ListCountriesResponse = WithTimestamp<{
  rawCountries: Country[];
  countries: DataPickerItemType[];
  countriesTable: {[key: string]: string};
}>;

type CurrencyListToOpenResponse = WithTimestamp<{
  currencies: Currency[];
}>;

type GenerateQRCodeResponse = string;

type FaqResponse = WithTimestamp<{
  faqs: QAPair[];
}>;

type AboutResponse = WithTimestamp<{
  title: string;
  content: string;
  contact: Contact[];
}>;

type GetBankAccountsResponse = WithTimestamp<{
  accounts: BankAccountInfo[];
}>

type SearchBanksResponse = Bank[];

type LocalBanksResponse = LocalBank[];

type SwiftCountriesResponse = WithTimestamp<{
  countries: SwiftCountry[];
}>;

type CommissionsResponse = WithTimestamp<{
  commissionsTable: CommissionsTable;
}>;

export type DocumentItem = {
  mandatory: boolean;
  order: number;
  title: string;
};

export type ListDocumentsResponse = DocumentItem[];

export type QRCodeTable = {[key: string]: string};

type CurrencyListToOpenPayload = {
  customer_id: number;
};
type CurrencyListPayload = {
  sort_by_price?: boolean;
};

export type UploadResponseItem = {
  Key: string;
  Value: string
}

export type UploadFileResponse = {
  value: UploadResponseItem[]
}

type GenerateQRCodePayload = {
  text: string;
}

type FaqPayload = {
  items: number;
}

type SearchBanksPayload = {
  swift_country_id: string;
  query: string;
}

type LocalBanksPayload = {
  country_id: string;
  currency_code: string;
}

type CommissionsPayload = {
  view: boolean;
}

type CurrencyListResponse = WithTimestamp<{
  currencies: CurrencyTable;
}>;

const listCountriesRestActions = createRestActions<
  ListCountriesResponse
>(ListCountriesRestActions);

const listDocumentsRestActions = createRestActions<
  ListDocumentsResponse
>(ListDocumentsRestActions);

const currencyListToOpenRestActions = createRestActions<
  CurrencyListToOpenResponse,
  CurrencyListToOpenPayload
>(CurrencyListToOpenRestActions);

const uploadFileRestActions = createRestActions<
  UploadFileResponse,
  any
>(UploadFileRestActions);

const generateQRCodeRestActions = createRestActions<
  GenerateQRCodeResponse,
  GenerateQRCodePayload
>(GenerateQRCodeRestActions);

const faqRestActions = createRestActions<
  FaqResponse,
  FaqPayload
>(FaqRestActions);

const aboutRestActions = createRestActions<
  AboutResponse
>(AboutRestActions);

const getBankAccountsRestActions = createRestActions<
  GetBankAccountsResponse
>(GetBankAccountsRestActions);

const searchBanksRestActions = createRestActions<
  SearchBanksResponse,
  SearchBanksPayload
>(SearchBanksRestActions);

const localBanksRestActions = createRestActions<
  LocalBanksResponse,
  LocalBanksPayload
>(LocalBanksRestActions);

const swiftCountriesRestActions = createRestActions<
  SwiftCountriesResponse
>(SwiftCountriesRestActions);

const commissionsRestActions = createRestActions<
  CommissionsResponse,
  CommissionsPayload
>(CommissionsRestActions);

const currencyListRestActions = createRestActions<
  CurrencyListResponse,
  CurrencyListPayload
>(CurrencyListRestActions);

type AddQRCodeToTablePayload = {
  key: string;
  url: string;
}



const GeneralRestActions = {
  listCountries: listCountriesRestActions,
  listDocuments: listDocumentsRestActions,
  currencyListToOpen: currencyListToOpenRestActions,
  uploadFile: uploadFileRestActions,
  generateQRCode: generateQRCodeRestActions,
  faq: faqRestActions,
  about: aboutRestActions,
  getBankAccounts: getBankAccountsRestActions,
  searchBanks: searchBanksRestActions,
  localBanks: localBanksRestActions,
  swiftCountries: swiftCountriesRestActions,
  commissions: commissionsRestActions,
  currencyList: currencyListRestActions
}

const GeneralActions = {
  ...GeneralRestActions,
  addQRCodeToTable: createAction<AddQRCodeToTablePayload>('general/addQRCodeToTable'),
  clearCurrencyListToOpen: createAction('general/clearCurrencyListToOpen'),
  
};

type GeneralRestNodes = keyof typeof GeneralActions;

type GeneralStore = {
  QRCodesTable: QRCodeTable;
}; 

const initialGeneralStore: GeneralStore = {
  QRCodesTable: {}
}

const initialRestState = {
  listCountries: getDefaultRestState<ListCountriesResponse>(),
  listDocuments: getDefaultRestState<ListDocumentsResponse>(),
  currencyListToOpen: getDefaultRestState<CurrencyListToOpenResponse>(),
  uploadFile: getDefaultRestState<UploadFileResponse>(),
  generateQRCode: getDefaultRestState<GenerateQRCodeResponse>(),
  faq: getDefaultRestState<FaqResponse>(),
  about: getDefaultRestState<AboutResponse>(),
  getBankAccounts: getDefaultRestState<GetBankAccountsResponse>(),
  searchBanks: getDefaultRestState<SearchBanksResponse>(),
  localBanks: getDefaultRestState<LocalBanksResponse>(),
  swiftCountries: getDefaultRestState<SwiftCountriesResponse>(),
  commissions: getDefaultRestState<CommissionsResponse>(),
  currencyList: getDefaultRestState<CurrencyListResponse>()
};

type GeneralState = NodeRestStateType<
  GeneralRestNodes, 
  GeneralStore & typeof initialRestState
>;

type Builder = ActionReducerMapBuilder<GeneralState>;

const generalReducer = createReducer(
  {...initialGeneralStore, ...initialRestState}, 
  builder =>
    (fp.flow(addAllRestReducers<typeof GeneralRestActions>(GeneralRestActions))(builder) as Builder)
    .addCase(GeneralActions.addQRCodeToTable, (state, action) => {
      const { key, url } = action.payload;

      state.QRCodesTable[key] = url;
    })
    .addCase(GeneralActions.clearCurrencyListToOpen, (state) => {
      state.currencyListToOpen = initialRestState.currencyListToOpen;
    })
);

export {generalReducer, GeneralActions};
