import { Instruction, InstructionGroup } from "@appklaar/codebud";
import { clearObjectFromEmptyStrings } from "./../helpers/formatHelpers";
import { CryptoActions } from "./../logic/crypto/CryptoRedux";

export const getCryptoInstructions = (store: any): (Instruction | InstructionGroup)[] => ([
  {
    groupId: "Crypto",
    groupDescription: "Crypto entity instructions",
    groupColor: "#40FDC8",
    groupInstructions: [
      {
        id: "getAccountsList",
        description: "Get a list of accounts. All filters are optional.",
        parametersDescription: {
          customer_id: "string",
          account_id: "string",
          currency: "string",
          update_crypto_balances: "string",
          total_currency: "string",
        },
        handler: (data) => {
          store.dispatch(
            CryptoActions.getAccountsList.request(
              clearObjectFromEmptyStrings(data)
            )
          );
        },
      },
      {
        id: "openAccount",
        description: "Open a new account for a user.",
        parametersDescription: {
          customer_id: "number",
          currency_id: "number",
        },
        handler: (data) => {
          store.dispatch(CryptoActions.openAccount.request(data));
        },
      },
      {
        id: "deleteAccount",
        description: "Delete an account.",
        parametersDescription: {
          id: "number",
        },
        handler: (data) => {
          store.dispatch(CryptoActions.deleteAccount.request(data));
        },
      },
      {
        id: "changeAccountStatus",
        description: "Change account status.",
        parametersDescription: {
          id: "number",
          status: "string",
        },
        handler: (data) => {
          store.dispatch(CryptoActions.changeAccountStatus.request(data));
        },
      },
      {
        id: "transfer",
        description: "Transer between accounts.",
        parametersDescription: {
          from_account_id: "number",
          to_account_id: "number",
          deposit_address: "string",
          to_blockchain: "string",
          amount: "number",
          otp_code: "string",
          check_only: "boolean",
          normal_sending: "boolean",
        },
        handler: (data) => {
          store.dispatch(CryptoActions.transfer.request(data));
        },
      },
      {
        id: "deposit",
        description:
          "Add fund to account. Send receipt (file) parameter if deposit was made by an offline transfer. This method is only available for fiat wallets.",
        parametersDescription: {
          to_account_id: "number",
          amount: "number",
          type: "string",
          receipt: "string",
        },
        handler: (data) => {
          store.dispatch(CryptoActions.deposit.request(data));
        },
      },
      {
        id: "buyCryptoElements",
        description: "Get a list of eligible accounts to buy crypto.",
        parametersDescription: {
          customer_id: "number",
        },
        handler: (data) => {
          store.dispatch(CryptoActions.buyCryptoElements.request(data));
        },
      },
      {
        id: "coingeckoMarketChartsRequest",
        description: "Get table of market charts from coingecko",
        handler: () => {
          store.dispatch(CryptoActions.coingeckoMarketCharts.request());
        },
      },
      {
        id: "getSwapReceive",
        description: "Get a list of eligible accounts for swap.",
        parametersDescription: {
          pay_account_id: "number"
        },
        handler: (data) => {
          const payload = !data.pay_account_id ? {} : data;
          store.dispatch(CryptoActions.swapReceive.request(payload));
        }
      },
    ]
  },
]);