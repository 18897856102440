import React, { memo } from "react";
import { useStyles } from "./MarketItemStyles";
import { Box } from "@material-ui/core";
import { CoinPriceTimestamp, NumberPair } from "../../types/types";
import { Trand, getAdaptivePriceFormatter } from "../../helpers/price";
import { useDependentValue } from "../../helpers/customHooks";
import { useThemeContext } from "../../theme";
import { CONFIG } from "../../config";
import { SimpleLineChart } from "../SimpleLineChart";
import currency from "currency.js";
import cn from "classnames";

export type MarketItemProps = {
  symbol: string;
  name: string;
  currentPrice: number;
  change: number;
  loadingPricesData?: boolean;
  pricesData: CoinPriceTimestamp[] | NumberPair[];
  dataType?: "object" | "array";
  image: string;
};

const MarketItem: React.FC<MarketItemProps> = ({
  symbol,
  name,
  currentPrice,
  change,
  loadingPricesData,
  pricesData,
  dataType,
  image,
}) => {
  const s = useStyles();
  const { theme } = useThemeContext();

  const coinPriceFormatted = currency(
    currentPrice,
    getAdaptivePriceFormatter(currentPrice)
  ).format();

  const trand = useDependentValue(
    () => (change < 0 ? Trand.Negative : Trand.Positive),
    [change]
  );

  const trandColor = useDependentValue(
    () => (trand === Trand.Negative ? theme.colors.red : theme.colors.sulu),
    [trand]
  );

  return (
    <Box className={cn(s.container)}>
      <Box className={cn(s.left)}>
        <Box className={cn(s.logoCont)}>
          <img src={image} className={cn(s.logo)} alt="currency logo" />
        </Box>
        <Box>
          <p className={cn(s.whiteBold)} style={{ textTransform: "uppercase" }}>
            {symbol}
          </p>
          <p
            className={cn(s.greyText)}
            style={{
              textTransform: "capitalize",
              fontSize: name.length > 10 ? 11 : 15,
            }}
          >
            {name}
          </p>
        </Box>
      </Box>

      <Box className={cn(s.center)}>
        <SimpleLineChart
          loadingData={loadingPricesData as boolean}
          data={pricesData}
          lineColor={trandColor}
          dataType={dataType}
        />
      </Box>

      <Box className={cn(s?.right)}>
        <p
          className={cn(s.whiteBold)}
          style={{ fontSize: coinPriceFormatted.length > 10 ? 14 : 16 }}
        >
          {coinPriceFormatted}
        </p>
        <p
          className={cn({
            [s.changeText]: true,
            [s.redText]: trand === Trand.Negative,
            [s.greenText]: trand === Trand.Positive,
          })}
        >
          {isFinite(change)
            ? `${currency(change, CONFIG.PERCENT_OPTIONS)}%`
            : "–"}
        </p>
      </Box>
    </Box>
  );
};

MarketItem.defaultProps = {
  loadingPricesData: false,
  dataType: "object",
};
const MemorizedComponent = memo(MarketItem);
export { MemorizedComponent as MarketItem };
