import { create } from "apisauce";
import * as T from './../types/api';
import { CONFIG } from "./../config";
import {
  AdResponse,
  BlogResponse,
  BuyCryptoElementsRequest,
  BuyCryptoElementsResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  ConfirmNewEmailRequest,
  ConfirmNewEmailResponse,
  CreateVirtualCardRequest,
  CreateVirtualCardResponse,
  CreateVisaCardRequest,
  CreateVisaCardResponse,
  CurrencyListToOpenRequest,
  CurrencyListToOpenResponse,
  DepositRequest,
  DepositResponse,
  DisableAuthenticatorCodeRequest,
  DisableAuthenticatorCodeResponse,
  FaqRequest,
  FaqResponse,
  GenerateQRCodeRequest,
  GenerateQRCodeResponse,
  GetAccountsListRequest,
  GetAccountsListResponse,
  GetAuthenticatorCodeResponse,
  GetMyCardsRequest,
  GetMyCardsResponse,
  GetStorieByIdRequest,
  GetStorieByIdResponse,
  GetStorieListRequest,
  GetStorieListResponse,
  ListCountriesResponse,
  LoginRequest,
  LoginResponse,
  LogoutResponse,
  NewsResponse,
  OpenAccountRequest,
  OpenAccountResponse,
  RegisterEmailRequest,
  RegisterEmailResponse,
  RegisterRequest,
  RegisterResponse,
  RequestResetPasswordRequest,
  RequestResetPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  VerifyAuthenticatorCodeRequest,
  VerifyAuthenticatorCodeResponse,
  WhoAmIResponse,
  AboutResponse,
  DeleteAccountRequest,
  DeleteAccountResponse,
  ChangeAccountStatusRequest,
  ChangeAccountStatusResponse,
  TransferRequest,
  TransferResponse,
  ConfirmMobileRequest,
  EditProfileRequest,
  EditProfileItem,
  GetTransactionsRequest,
  GetTransactionsResponse,
  TicketsListResponse,
  TicketsListRequest,
  TicketOpenRequest,
  TicketOpenResponse,
  TicketReplyRequest,
  TicketReplyResponse,
  TicketsTypesResponse,
  GetBankAccountsResponse,
  CoingeckoMarketChartsResponse,
  EditProfileResponse,
  ConvertCurrencyRequest,
  ConvertCurrencyResponse,
  TicketChangeRequest,
  AllowedCountriesResponse,
  CardFeaturesResponse,
  IssueVirtualCardRequest,
  IssueVirtualCardResponse,
  GetCVVRequest,
  GetCVVResponse,
  LoadVirtualCardRequest,
  CardTransactionsRequest,
  CardTransactionsResponse,
  CardToCardRequest,
  CardToCardResponse,
  LoadVirtualCardRespone,
  LoadPhysicalCardRequest,
  LoadPhysicalCardResponse,
  RemitRequest,
  RemitResponse,
  NewHolderRequest,
  NewHolderResponse,
  SubmitKycRequest,
  SubmitKycResponse,
  ActivateRequest,
  ActivateResponse,
  UnloadVirtualCardRequest,
  UnloadVirtualCardResponse,
  UnloadPhysicalCardRequest,
  UnloadPhysicalCardResponse,
  SwapReceiveRequest,
  SwapReceiveResponse,
  SearchBanksRequest,
  SearchBanksResponse,
  LocalBanksRequest,
  LocalBanksResponse,
  SwiftCountriesResponse,
  SetNotificationsRequest,
  SetNotificationsResponse,
  CardCommissionsResponse,
  CommissionsRequest,
  CommissionsResponse,
  IntercomUserHashResponse,
  GetPlansResponse,
  GetContractPostResponse,
  FinancialReportRequest,
  FinancialReportResponse,
  DeleteNftProfileRequest,
  DeleteNftProfileResponse,
  EditNftProfileRequest,
  ListNftUsersRequest,
  ListCategoriesResponse,
  ListCollectionsRequest,
  ChangePlanRequest,
  ChangeLikeStatusRequest,
  ListNftsRequest,
  ChangeFollowStatusRequest,
  EditNftProfileResponse,
  ListNftUsersResponse,
  ListCollectionsResponse,
  ChangePlanResponse,
  ChangeLikeStatusResponse,
  ListNftsResponse,
  ChangeFollowStatusResponse,
  CreateNftProfileResponse,
  CreateNftProfileRequest,
  GetMyZedAuthTokenResponse,
  ListContractsResponse,
  ListContractsRequest,
  CreateNftResponse,
  CreateNftRequest,
  CurrencyListRequest,
  CurrencyListResponse,
  CreateNftCollectionRequest,
  CreateNftCollectionResponse,
  IntercomUserHashRequest,
} from "./../types/api";
import { addAxiosResponseInterceptor } from "./handleRedirect";
import {
  ListDocumentsResponse,
  UploadFileResponse,
} from "../logic/general/GeneralRedux";
import {
  SendDocumentsPayload,
  ZedAPIDefaultResponse,
} from "../logic/user/UserRedux";

const PAGINATION_CONFIG = {
  // Number of results to return per page
  limit: 15,
};

const sauce = create({
  baseURL: CONFIG.BASE_URL,
  headers: { Accept: "application/json" },
});
addAxiosResponseInterceptor(sauce);

// Is not authorized by default. Token can be set with updateAuthorizationHeader
const sauceAuthorized = create({
  baseURL: CONFIG.BASE_URL,
  headers: { Accept: "application/json" },
});
addAxiosResponseInterceptor(sauceAuthorized);

const createSauceForUploadPhoto = (token: string) => {
  const sauceUploadPhotoAuthorized = create({
    baseURL: CONFIG.BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
  addAxiosResponseInterceptor(sauceUploadPhotoAuthorized);

  return sauceUploadPhotoAuthorized;
};

// Updates sauceAuthorized instance (puts new token into headers)
const updateAuthorizationHeader = (token: string) => {
  sauceAuthorized.setHeader("Authorization", `Bearer ${token}`);
};

const api = {
  registerEmailPost: (params: RegisterEmailRequest) => sauce.post<RegisterEmailResponse>(`/user/register_email`, params),
  confirmNewEmailPost: (params: ConfirmNewEmailRequest) => sauce.post<ConfirmNewEmailResponse>(`/user/confirm_new_email`, params),
  listCountriesGet: () => sauce.get<ListCountriesResponse>(`/general/list_countries`),
  registerPost: (params: RegisterRequest) => sauce.post<RegisterResponse>(`/user/register`, params),
  loginPost: (params: LoginRequest) => sauce.post<LoginResponse>(`/user/login`, params),
  whoAmIGet: () => sauceAuthorized.get<WhoAmIResponse>(`/user/who_am_i`),
  logoutGet: () => sauceAuthorized.get<LogoutResponse>(`/user/close_session`),
  changePasswordPost: (params: ChangePasswordRequest) => sauceAuthorized.post<ChangePasswordResponse>(`/user/change_password`, params),
  requestResetPasswordPost: (params: RequestResetPasswordRequest) => sauce.post<RequestResetPasswordResponse>(`/user/request_reset_password`, params),
  resetPasswordPost: (params: ResetPasswordRequest) => sauce.post<ResetPasswordResponse>(`/user/reset_password`, params),
  getAuthenticatorCodeGet: () => sauceAuthorized.get<GetAuthenticatorCodeResponse>(`/user/get_authenticator_code`),
  verifyAuthenticatorCodePost: (params: VerifyAuthenticatorCodeRequest) => sauceAuthorized.post<VerifyAuthenticatorCodeResponse>(`/user/verify_authenticator_code`, params),
  disableAuthenticatorCodePost: (params: DisableAuthenticatorCodeRequest) => sauceAuthorized.post<DisableAuthenticatorCodeResponse>(`/user/disable_authenticator_code`, params),
  listDocumentsGet: () => sauceAuthorized.get<ListDocumentsResponse>(`/general/list_document_types`),
  newsGet: () => sauceAuthorized.get<NewsResponse>(`/news/list/0`),
  blogGet: () => sauce.get<BlogResponse>(`/blog/list`),
  adGet: () => sauceAuthorized.get<AdResponse>(`/ad/list/0`),
  getStorieByIdGet: (params: GetStorieByIdRequest) => sauceAuthorized.get<GetStorieByIdResponse>(`/storie/one/${params.storieId}`),
  getStorieListGet: (params: GetStorieListRequest) => sauceAuthorized.get<GetStorieListResponse>(`/storie/list/${params.index}`),
  getAccountsListGet: (params: GetAccountsListRequest) => sauceAuthorized.get<GetAccountsListResponse>(`/account/list`, params),
  openAccountPost: (params: OpenAccountRequest) => sauceAuthorized.post<OpenAccountResponse>(`/account/open`, params),
  currencyListToOpenGet: (params: CurrencyListToOpenRequest) => sauceAuthorized.get<CurrencyListToOpenResponse>(`/currency/list_to_open`, params),
  uploadFile: (params: any, token: string) => {
    let sauce = createSauceForUploadPhoto(token);
    return sauce.post<UploadFileResponse>(`/general/upload_file`, params);
  },
  sendDocumentsPost: (params: SendDocumentsPayload) => sauceAuthorized.post<any>(`/user/submit_documents`, params),
  getMyCards: (params: GetMyCardsRequest) => sauceAuthorized.get<GetMyCardsResponse>(`/card/list`, params),
  createVirtualCard: (params: CreateVirtualCardRequest) => sauceAuthorized.post<CreateVirtualCardResponse>(`/account/new_virtual_card`, params),
  createVisaCard: (params: CreateVisaCardRequest) => sauceAuthorized.post<CreateVisaCardResponse>(`/account/new_visa_card`, params),
  depositPost: (params: DepositRequest) => sauceAuthorized.post<DepositResponse>(`/account/deposit`, params),
  buyCryptoElementsGet: (params: BuyCryptoElementsRequest) => sauceAuthorized.get<BuyCryptoElementsResponse>(`/account/buy_crypto_elements`, params),
  generateQRCodeGet: (params: GenerateQRCodeRequest) => sauceAuthorized.get<GenerateQRCodeResponse>(`/general/generate_qr_code`, params),
  faqGet: (params: FaqRequest) => sauce.get<FaqResponse>(`/general/faq`, params),
  aboutGet: () => sauce.get<AboutResponse>(`/general/about`),
  deleteAccountPost: (params: DeleteAccountRequest) => sauceAuthorized.post<DeleteAccountResponse>(`/account/delete`, params),
  changeAccountStatusPost: (params: ChangeAccountStatusRequest) => sauceAuthorized.post<ChangeAccountStatusResponse>(`/account/change`, params),
  transferPost: (params: TransferRequest) => sauceAuthorized.post<TransferResponse>(`/account/transfer`, params),
  verifyPhone: (id: number) => sauceAuthorized.post<any>(`/user/verify_mobile`, { id }),
  confirmPhone: (params: ConfirmMobileRequest) => sauceAuthorized.post<any>(`/user/confirm_mobile`, params),
  editProfile: (params: EditProfileItem) => sauceAuthorized.post<EditProfileResponse>(`/user/edit_profile`, params),
  getTransactions: (params: GetTransactionsRequest) => sauceAuthorized.get<GetTransactionsResponse>(`/account/transactions`, params),
  ticketsList: (params: TicketsListRequest) => sauceAuthorized.get<TicketsListResponse>(`/ticket/list?items=${params.items}&user_id=${params.user_id}&id=${params.id}`),
  ticketsTypes: () => sauceAuthorized.get<TicketsTypesResponse>(`/ticket/list_departments`),
  ticketOpen: (params: TicketOpenRequest) => sauceAuthorized.post<TicketOpenResponse>(`/ticket/open`, params),
  ticketReply: (params: TicketReplyRequest) => sauceAuthorized.post<TicketReplyResponse>(`/ticket/reply`, params),
  getBankAccountsGet: () => sauceAuthorized.get<GetBankAccountsResponse>(`/general/get_bank_accounts`),
  coingeckoMarketChartsGet: () => sauceAuthorized.get<CoingeckoMarketChartsResponse>(`/currency/table`),
  convertCurrencyPost: (params: ConvertCurrencyRequest) => sauceAuthorized.post<ConvertCurrencyResponse>(`/currency/convert`, params),
  ticketChange: (params: TicketChangeRequest) => sauceAuthorized.post<TicketOpenResponse>(`/ticket/change`, params),
  allowedCountriesGet: () => sauceAuthorized.get<AllowedCountriesResponse>(`/card/list_countries`),
  cardFeaturesGet: () => sauceAuthorized.get<CardFeaturesResponse>(`/card/features`),
  issueVirtualCardPost: (params: IssueVirtualCardRequest) => sauceAuthorized.post<IssueVirtualCardResponse>(`/card/issue_virtual_card`, params),
  getCVVGet: (params: GetCVVRequest) => sauceAuthorized.get<GetCVVResponse>(`/card/get_cvv`, params),
  loadVirtualCardPost: (params: LoadVirtualCardRequest) => sauceAuthorized.post<LoadVirtualCardRespone>(`/card/load_virtual_card`, params),
  loadPhysicalCardPost: (params: LoadPhysicalCardRequest) => sauceAuthorized.post<LoadPhysicalCardResponse>(`/card/load_physical_card`, params),
  changeAvatar: (params: { id: number; avatar: string }) => sauceAuthorized.post<ZedAPIDefaultResponse>(`/user/change_avatar`, params),
  cardTransactionsGet: (params: CardTransactionsRequest) => sauceAuthorized.get<CardTransactionsResponse>(`/card/transactions`, params),
  cardToCardPost: (params: CardToCardRequest) => sauceAuthorized.post<CardToCardResponse>(`/card/card_to_card`, params),
  remitPost: (params: RemitRequest) => sauceAuthorized.post<RemitResponse>(`/account/remit`, params),
  newHolderPost: (params: NewHolderRequest) => {
    const query = params.virtual ? { virtual: true } : undefined;
    delete params.virtual;
    return sauceAuthorized.post<NewHolderResponse>(`/card/new_holder`, params, {params: query});
  },
  submitKycPost: (params: SubmitKycRequest) => sauceAuthorized.post<SubmitKycResponse>(`/card/submit_kyc`, params),
  activatePost: (params: ActivateRequest) => sauceAuthorized.post<ActivateResponse>(`/card/activate`, params),
  unloadVirtualCardPost: (params: UnloadVirtualCardRequest) => sauceAuthorized.post<UnloadVirtualCardResponse>(`/card/unload_virtual_card`, params),
  unloadPhysicalCardPost: (params: UnloadPhysicalCardRequest) => sauceAuthorized.post<UnloadPhysicalCardResponse>(`/card/unload_physical_card`, params),
  swapReceiveGet: (params: SwapReceiveRequest) => sauceAuthorized.get<SwapReceiveResponse>(`/account/get_swap_receive`, params),
  searchBanksGet: (params: SearchBanksRequest) => sauceAuthorized.get<SearchBanksResponse>(`/general/search_banks`, params),
  localBanksGet: (params: LocalBanksRequest) => sauceAuthorized.get<LocalBanksResponse>(`/general/get_local_banks`, params),
  swiftCountriesGet: () => sauceAuthorized.get<SwiftCountriesResponse>(`/general/swift_countries`),
  setNotificationsPost: (params: SetNotificationsRequest) => sauceAuthorized.post<SetNotificationsResponse>(`/user/set_notifications`, params),
  cardCommissionsGet: () => sauceAuthorized.get<CardCommissionsResponse>(`/card/commissions`),
  commissionsGet: (params: CommissionsRequest) => sauceAuthorized.get<CommissionsResponse>(`/general/get_commissions`, params),
  intercomUserHashGet: (params: IntercomUserHashRequest) => sauceAuthorized.get<IntercomUserHashResponse>(`/user/get_intercom_user_hash/${params.platform}`),
  getPlansGet: () => sauceAuthorized.get<GetPlansResponse>('/nft/get_plans'),
  getContractCostPost: () => sauceAuthorized.post<GetContractPostResponse>(`/nft/get_contract_cost`),
  financialReportGet: (params: FinancialReportRequest) => sauceAuthorized.get<FinancialReportResponse>('/nft/financial_report', params),
  deleteNftProfilePost: (params: DeleteNftProfileRequest) => sauceAuthorized.post<DeleteNftProfileResponse>(`/nft/delete_profile`, params),
  editNftProfilePost: (params: EditNftProfileRequest) => sauceAuthorized.post<EditNftProfileResponse>(`/nft/edit_user`, params),
  editNftPost: (params: T.EditNftRequest) => sauceAuthorized.post<T.EditNftResponse>(`/nft/edit`, params),
  listNftUsersGet: (params: ListNftUsersRequest) => sauceAuthorized.get<ListNftUsersResponse>('/nft/list_users', params),
  listCategoriesGet: () => sauceAuthorized.get<ListCategoriesResponse>('/nft/list_categories'),
  listCollectionsGet: (params: ListCollectionsRequest) => sauceAuthorized.get<ListCollectionsResponse>('/nft/list_collections', params),
  changePlanPost: (params: ChangePlanRequest) => sauceAuthorized.post<ChangePlanResponse>(`/nft/change_plan`, params),
  changeLikeStatusPost: (params: ChangeLikeStatusRequest) => sauceAuthorized.post<ChangeLikeStatusResponse>(`/nft/change_like_status`, params),
  listNftsGet: (params: ListNftsRequest) => sauceAuthorized.get<ListNftsResponse>('/nft/list', params),
  changeFollowStatusPost: (params: ChangeFollowStatusRequest) => sauceAuthorized.post<ChangeFollowStatusResponse>(`/nft/change_follow_status`, params),
  createNftProfilePost: (params: CreateNftProfileRequest) => sauceAuthorized.post<CreateNftProfileResponse>(`/nft/register`, params),
  createNftCollectionPost: (params: CreateNftCollectionRequest) => sauceAuthorized.post<CreateNftCollectionResponse>(`/nft/create_collection`, params),
  editNftCollectionPost: (params: T.EditNftCollectionRequest) => sauceAuthorized.post<T.EditNftCollectionResponse>(`/nft/edit_collection`, params),
  listContractsGet: (params: ListContractsRequest) => sauceAuthorized.get<ListContractsResponse>(`/nft/list_contracts`, params),
  getMyZedAuthTokenGet: () => sauceAuthorized.get<GetMyZedAuthTokenResponse>(`/user/get_my_zedauth_token`),
  createNftPost: (params: CreateNftRequest) => sauceAuthorized.post<CreateNftResponse>(`/nft/create`, params),
  currencyListGet: (params: CurrencyListRequest) => sauceAuthorized.get<CurrencyListResponse>(`/currency/list`, params),

}

export { api, updateAuthorizationHeader, PAGINATION_CONFIG };