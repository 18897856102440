import { FC, useCallback, useEffect, useState } from "react";
import {
  ContentWrapper,
  IconBack,
  IconWrapper,
  MenuIcon,
  MenuItem,
  UserArea,
  UserIcon,
  Wrapper,
} from "./DropDownStyles";
import { useThemeContext } from "../../../theme";
import { Text } from "../../Text/Text";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../../logic/user/UserRedux";
import { userSelector } from "../../../logic/user/UserSelectors";
import { CONFIG } from "../../../config";
import styled from "styled-components";
import { prepareImageName } from "../../../helpers/formatHelpers";
import LoaderRing from "../../../helpers/Loader/LoaderRing";
import { templates } from "../../../theme/templates";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { RefferalId } from "../../RefferalId";
import { isMobile } from "react-device-detect";
import { StoriesBlock } from "../../StoriesBlock";
import { Box } from "@material-ui/core";
import AvatarUploader from "../../../helpers/AvatarUploader/AvatarUploader";

type Props = {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setModalLogout: React.Dispatch<React.SetStateAction<boolean>>;
  setHistory: React.Dispatch<React.SetStateAction<boolean>>;
};

const data = [
  { img: "/images/user.png", title: "Verification", href: "/main/verification" },
  {
    img: "/images/user.png",
    title: "Profile details",
    href: "/main/profile",
    id: "profile",
  },
  {
    img: "/images/setting.png",
    title: "Settings",
    href: "/main/profile",
    id: "settings",
  },
  {
    img: "/images/question.png",
    title: "ZedPay support",
    href: "/main/support",
  },
  {
    img: "/images/question.png",
    title: "ZedCex support",
    href: "",
    id: "zedCexSupport",
  },
  { img: "/images/question.png", title: "FAQ", href: "/main/faq" },
  { img: "/images/exclamation.png", title: "About", href: "/main/about" },
  { img: "/images/user.png", title: "Logout", href: "", id: "logout" },
];

const DropDownMenu: FC<Props> = ({
  setVisible,
  setModalLogout,
  setHistory,
}) => {
  const { theme } = useThemeContext();
  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isAvatarUploaderOpen, setIsAvatarUploaderOpen] = useState(false);

  const {
    whoAmI: {
      data: profile,
      // fetching: fetchingProfile
    },
    changeAvatar: { fetching: fetch },
    userId,
  } = useSelector(userSelector);

  // change img
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  useEffect(() => {
    if (selectedFile) {
      const avatar = new FormData();
      avatar.append(
        "newUserAvatar" + profile?.email,
        selectedFile,
        prepareImageName(selectedFile.name)
      );

      dispatch(UserActions.changeAvatar.request({ id: userId ?? 0, avatar }));
    }
  }, [selectedFile]);

  const handleMenuItemPress = useCallback((id: string | undefined) => {
    switch (id) {
      case "logout":
        setModalLogout(true);
        setVisible(false);
        break;
      case "history":
        setHistory(true);
        setVisible(false);
        break;
      case "zedCexSupport":
        // @ts-ignore
        window.Intercom("show");
        break;
      default:
        break;
    }
  }, []);

  const navigationWithState = (item: string | undefined) => {
    if (item === "profile") {
      return { state: "viewing" };
    }
    if (item === "settings") {
      return { state: "edit" };
    }

    return null;
  };

  const windowWidth = useWindowWidth();

  return (
    <>
      <Wrapper onClick={() => setVisible(false)}>
        <ContentWrapper onClick={(e) => e.stopPropagation()}>
          {windowWidth < 500 && (
            <IconBack
              src="/images/support/back_arrow-yellow.svg"
              onClick={() => setVisible(false)}
            />
          )}

          <>
            <UserArea>
              <UserIconWrapper
                onClick={() => setIsAvatarUploaderOpen((prev) => !prev)}
              >
                {fetch && (
                  <Loader>
                    <LoaderRing size="26px" color={theme.colors.yellowMain} />
                  </Loader>
                )}

                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  accept=".jpg, .jpeg, .png"
                  onChange={handleFileChange}
                />
                <UserIcon src={CONFIG.ZED_BASE_URL + profile?.avatar} />
              </UserIconWrapper>
              <Text
                color={theme.colors.white}
                size="18px"
                margin="10px 0 0 0"
                fontStyle={theme.fonts.f600}
              >
                {profile?.email}
              </Text>

              <RefferalId />

              {isMobile && (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    paddingBottom: 4,
                  }}
                >
                  <StoriesBlock />
                </Box>
              )}
            </UserArea>
            {windowWidth < 450 && (
              <>
                <Link to={"support"} onClick={() => setVisible(false)}>
                  <MenuItem onClick={() => handleMenuItemPress("hello")}>
                    <IconWrapper>
                      <MenuIcon src="/images/support_menu.svg" />
                    </IconWrapper>
                    <Text
                      color={theme.colors.white}
                      size="21px"
                      fontStyle={theme.fonts.f500}
                      hoverColor={theme.colors.yellowMain}
                    >
                      Support
                    </Text>
                  </MenuItem>
                </Link>
                <MenuItem
                  onClick={() => {
                    handleMenuItemPress("history");
                  }}
                >
                  <IconWrapper>
                    <MenuIcon src="/images/history_menu.svg" />
                  </IconWrapper>
                  <Text
                    color={theme.colors.white}
                    size="21px"
                    fontStyle={theme.fonts.f500}
                    hoverColor={theme.colors.yellowMain}
                  >
                    History
                  </Text>
                </MenuItem>
              </>
            )}
            {data.map((el, index) => {
              if (el.title === "Logout") {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleMenuItemPress(el.id);
                    }}
                  >
                    <IconWrapper>
                      <MenuIcon src={el.img} />
                    </IconWrapper>
                    <Text
                      color={theme.colors.white}
                      size="21px"
                      fontStyle={theme.fonts.f500}
                      hoverColor={theme.colors.yellowMain}
                    >
                      {el.title}
                    </Text>
                  </MenuItem>
                );
              } else {
                return (
                  <Link
                    to={el.href}
                    key={index}
                    state={navigationWithState(el.id)}
                    onClick={() => setVisible(false)}
                  >
                    <MenuItem onClick={() => handleMenuItemPress(el.id)}>
                      <IconWrapper>
                        <MenuIcon src={el.img} />
                      </IconWrapper>
                      <Text
                        color={theme.colors.white}
                        size="21px"
                        fontStyle={theme.fonts.f500}
                        hoverColor={theme.colors.yellowMain}
                      >
                        {el.title}
                      </Text>
                    </MenuItem>
                  </Link>
                );
              }
            })}
          </>
        </ContentWrapper>
      </Wrapper>
      {isAvatarUploaderOpen && (
        <AvatarUploader
          setAvatarUploaderOpen={setIsAvatarUploaderOpen}
          setSelectedFile={setSelectedFile}
        />
      )}
    </>
  );
};
export default DropDownMenu;

const UserIconWrapper = styled.div`
  position: relative;
  ${templates.centerContent};
  min-width: 90px;
  max-width: 90px;
  height: 90px;
`;
const Loader = styled.div`
  position: absolute;
`;
