import styled from "styled-components";
import { Text } from "../../../../components/Text/Text";
import { useThemeContext } from "../../../../theme";
import { Picture, PictureWrapper, Right, Wrapper } from "./InfoNFTStyle";
import DescriptionNFT from "./components/DescriptionNFT";
import HeaderNFT from "./components/HeaderNFT";
import Person from "./components/Person";
import Tags from "./components/TagsNFT";
import { templates } from "../../../../theme/templates";
import { FC } from "react";
import { NftItem } from "../../../../types/types";
import { getNftImage } from "../../../../helpers/nftHelpers";
import { CONFIG } from "../../../../config";
import { getAdaptivePriceFormatter } from "../../../../helpers/price";
import currency from "currency.js";
import { useDependentValue } from "../../../../helpers/customHooks";
import { generalSelector } from "../../../../logic/general/GeneralSelectors";
import { useSelector } from "react-redux";
import i18next from "i18next";

type Props = {
  data: NftItem;
};

const InfoNFT: FC<Props> = ({ data }) => {
  const { theme } = useThemeContext();
  const { t } = i18next;
  const {
    currencyList: { data: currencyList },
  } = useSelector(generalSelector);

  const fiatPriceFormatted = useDependentValue(() => {
    if (
      !currencyList ||
      !currencyList.currencies ||
      !data ||
      !data.currency_id
    ) {
      return "";
    }
    const currencyInfo = currencyList.currencies[data.currency_id];
    if (!currencyInfo || typeof currencyInfo.sell === "undefined") {
      return "";
    }
    const fiatPrice = data.price * currencyInfo.sell;
    return ` ≈ ${currency(
      fiatPrice,
      getAdaptivePriceFormatter(fiatPrice, "$")
    ).format()}`;
  }, [data, currencyList]);

  return (
    <Wrapper>
      <PictureWrapper>
        <Picture src={`${CONFIG.ZED_BASE_URL}${getNftImage(data)}`} />
      </PictureWrapper>
      <Right>
        <HeaderNFT data={data} />
        <DescriptionNFT
          price={data.price}
          currency={data.currency_code}
          abt={fiatPriceFormatted}
          currency_id={data.currency_id}
          descriptionText={data.description}
          avatar={`${CONFIG.ZED_BASE_URL}${data.nft_user?.logo}`}
          collection={data?.collection}
          royalties={data?.royalties}
          category={data?.category}
          userName={data?.nft_user?.username}
        />

        {/* <Tabs tabsData={tabs} /> */}

        <Person
          subTitle="Owner"
          img={`${CONFIG.ZED_BASE_URL}${data.nft_user?.logo}`}
          name={data.nft_user?.username}
        />
        <Text
          color={theme.colors.greySubText}
          size="20px"
          fontStyle={theme.fonts.f600}
          margin="30px 0 10px 0"
        >
          {t("nftDetailsScreen.properties")}
        </Text>
        {data.properties?.map((el, index) => (
          <Text
            color={theme.colors.white}
            size="20px"
            fontStyle={theme.fonts.f600}
            margin="0 0 10px 0"
            key={index}
          >
            {el.title}:
            <Text
              color={theme.colors.yellowMain}
              size="22px"
              fontStyle={theme.fonts.f500}
            >
              {el.value}
            </Text>
          </Text>
        ))}
        <Tags data={data as NftItem} collection={data.collection} />

        {/* <Container>
          <Text
            color={theme.colors.greySubText}
            size="24px"
            fontStyle={theme.fonts.f600}
          >
            {t("nftDetailsScreen.youCanBuy")} 333 ZEDXION
          </Text>
          <Btn
            label={t("nftDetailsScreen.buyNow")}
            type={"NFT"}
            buttonActive={true}
          />
        </Container> */}
      </Right>
    </Wrapper>
  );
};
export default InfoNFT;
const Container = styled.div`
  ${templates.centerContent};
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.subBackground};
  padding: 32px 24px;
  border-radius: 24px;
  border: 1px solid ${(p) => p.theme.colors.greySubText};
  margin-top: 24px;
`;
