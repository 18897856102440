import React from "react";
import { useStyles } from "./StoriesBlockStyles";
import { Box } from "@material-ui/core";
import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { storieSelector } from "../../logic/storie/StorieSelectors";
import { StorieActions } from "../../logic/storie/StorieRedux";
import { StoriesItem } from "../StoriesItem";
import LoaderRing from "../../helpers/Loader/LoaderRing";
import { theme } from "../../theme/default/theme";
import SkeletonStories from "./Skeleton";

export type StoriesBlockProps = {};

const StoriesBlock: React.FC<StoriesBlockProps> = ({}) => {
  const dispatch = useDispatch();
  const s = useStyles();

  const {
    getStorieList: { data: storieData },
  } = useSelector(storieSelector);

  //if (!storieData) return null;

  return (
    <Box className={cn(s.container)}>
      {storieData ? (
        storieData.map((storie, index) => (
          <StoriesItem
            key={storie.id}
            image={storie.items[0] ? storie.items[0].image ?? "" : ""}
            title={storie.title}
            viewed={false}
            onPress={() => {
              dispatch(StorieActions.setOpenedStorie({ ...storie, index }));
              dispatch(StorieActions.setStoriesModalVisible(true));
            }}
          />
        ))
      ) : (
        <>
          <SkeletonStories />
          <SkeletonStories />
        </>
      )}
      {}
    </Box>
  );
};

StoriesBlock.defaultProps = {};

export { StoriesBlock };
