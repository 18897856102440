import { Action } from '@reduxjs/toolkit';
import { ApiResponse } from 'apisauce';
import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { api, RequestErrorFromResponse } from '../../api';
import { StorieActions } from './StorieRedux';
import { GetStorieByIdResponse, GetStorieListResponse } from '../../types/api';
import { retryApiCall } from '../../helpers/sagaHelpers';

function* getStorieListRequest(action: Action) {
  if (StorieActions.getStorieList.request.match(action)) {
    try {
      const getStorieListResponse: ApiResponse<GetStorieListResponse> = yield call(retryApiCall, {
        apiRequest: api.getStorieListGet,
        args: [action.payload],
        maxTries: 2,
        delayMs: 2e3
      });
      if (getStorieListResponse.ok && getStorieListResponse.data) {
        const responseData = getStorieListResponse.data;

        for (const storie of responseData.stories) {
          storie.items = storie.items.map((storieItem, index) => ({...storieItem, id: `${storie.id}_${index}`}));
        }
        
        yield put(StorieActions.getStorieList.success(responseData.stories));
      } else {
        throw new RequestErrorFromResponse(getStorieListResponse);
      }
    } catch (error) {
      yield put(StorieActions.getStorieList.failure(error));
    }
  }
}

function* getStorieByIdRequest(action: Action) {
  if (StorieActions.getStorieById.request.match(action)) {
    try {      
      const getStorieByIdResponse: ApiResponse<GetStorieByIdResponse> = yield call(
        api.getStorieByIdGet,
        action.payload
      );
      if (getStorieByIdResponse.ok && getStorieByIdResponse.data) {
        const responseData = getStorieByIdResponse.data;
        yield put(StorieActions.getStorieById.success(responseData.storie));
      } else {
        throw new RequestErrorFromResponse(getStorieByIdResponse);
      }
    } catch (error) {
      yield put(StorieActions.getStorieById.failure(error));
    }
  }
}

export function* StorieSaga() {
  yield* [
    takeLatest(StorieActions.getStorieById.request.type, getStorieByIdRequest),
    takeLatest(StorieActions.getStorieList.request.type, getStorieListRequest)
  ];
}